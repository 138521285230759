import React from 'react';
import styled from 'styled-components';
import { TiStarburst } from 'react-icons/ti';
import IUserRank from '../../interfaces/IUserRank';
import './RankDisplay.scss';

// Styled rank name
const StyledRankName: any = styled.span<{ color: string }>`
    color: ${props => props.color};
`;

const RankDisplay: React.FC<{ rank: IUserRank, className?: string|undefined }> = props => {
    return (
        <p className={`rankDisplay ${props.className ?? ''}`}>
            <TiStarburst className='icon' fill={props.rank.color}/>
            <StyledRankName color={props.rank.color}>
                {props.rank.name}
            </StyledRankName>
        </p>
    );
};

export default RankDisplay;
