import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * UserAPIs class.
 */
export default class UserAPIs {
    /**
     * Url on API for the User resource
     */
     private static resourceUrl: string = 'users';

    /**
     * Get collection of Users
     * 
     * @param apiKey
     * @param query
     */
    public static async getUsers(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(this.resourceUrl, apiKey, query, false);
    }

    /**
     * Get specific user
     * 
     * @param id
     */
    public static async getUser(id: number) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(`${this.resourceUrl}/${id}`, apiKey, null, false);
    }

    /**
     * Get velocities of Editors
     * 
     * @param apiKey
     */
     public static async getEditorsVelocities() {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(`${this.resourceUrl}/velocity`, apiKey, [], false);
    }

    /**
     * Put a User
     * 
     * @param id
     * @param body
     */
     public static async putUser(id: number, body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPutCall(this.resourceUrl, id, null, apiKey, body, false);
    }
};
