import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * PostAPIs class.
 */
export default class PostAPIs {
    /**
     * Url on API for the Post resource
     */
    private static resourceUrl: string = 'posts';

    /**
     * Get collection of Posts
     * 
     * @param apiKey
     * @param query
     */
    public static async getPosts(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(this.resourceUrl, apiKey, query, false);
    }

    /**
     * Put a Post
     * 
     * @param id
     * @param body
     */
    public static async putPost(id: number, body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPutCall(this.resourceUrl, id, null, apiKey, body, false);
    }

    /**
     * Post a Post
     * 
     * @param body
     */
    public static async postPost(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(this.resourceUrl, apiKey, body, undefined, false);
    }

    /**
     * Delete a Post
     * 
     * @param id
     */
    public static async deletePost(id: number) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiDeleteCall(`${this.resourceUrl}/${id}`, apiKey, false);
    }
};
