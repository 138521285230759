import React from 'react';
import moment from 'moment-timezone';
import { FaUserCircle, FaEgg, RiMedalLine, GiStairsGoal } from 'react-icons/all';
import Tools from '../../helpers/Tools';
import connectionInterval from '../../configs/connectionInterval';
import DataDisplay from '../DataDisplay/DataDisplay';
import RankDisplay from '../RankDisplay/RankDisplay';
import Tooltip from '../Tooltip/Tooltip';
import SplitText from '../SplitText/SplitText';
import IUser from '../../interfaces/IUser';
import { useCallback } from 'react';

const Editor: React.FC<{ editor: IUser, velocity: any }> = props => {
    // Callback used to determine if an editor is ok (connected today)
    const isEditorConnectionOk = useCallback(() => {
        return (
            // Connection is same or between starting hours (minutes closely)
            moment(props.editor.connectionAt).isBetween(
                connectionInterval.begin,
                connectionInterval.end,
                'minutes',
                '[]' // all inclusive (isSameOrBetween)
            )
        );
    }, [props.editor.connectionAt])

    return (
        <div className='editorContainer'>
            <div className='dataStructure'>
                <div className='identityStructure'>
                    <FaUserCircle className='avatar' />
                    <DataDisplay
                        inline
                        icon={FaEgg}
                        label='Crée le'
                        data={Tools.humanizeTimestamp(props.editor.createdAt, true)}
                    />
                    <p className='editorName'>
                        {`${props.editor.name} ${props.editor.lastname.toUpperCase()}`}
                    </p>
                    {
                        props.editor.connectionAt ? (
                            <p className={`connection ${isEditorConnectionOk() ? '' : 'badConnection'}`}>
                                {`Connection à ${moment(props.editor.connectionAt).format('H:mm')}`}
                            </p>
                        ) : (
                            <p className='connection'>Aucune connection</p>
                        )
                    }
                    {
                        null !== props.editor.userRank && (
                            <RankDisplay rank={props.editor.userRank} />
                        )
                    }
                </div>
                <div className='velocitiesStructure'>
                    <SplitText className='title' text='Suivi de rédaction' />
                    <p className='velocity'>
                        Aujourd'hui :
                        <span className='value'>{props.velocity.today}</span>
                    </p>
                    <p className='velocity'>
                        Hier :
                        <span className='value'>{props.velocity.yesterday}</span>
                    </p>
                    <p className='velocity'>
                        Cette semaine :
                        <span className='value'>{props.velocity.week}</span>
                    </p>
                    <p className='velocity'>
                        Ce mois :
                        <span className='value'>{props.velocity.month}</span>
                    </p>
                    <p className='velocity'>
                        Cette année :
                        <span className='value'>{props.velocity.year}</span>
                    </p>
                </div>
            </div>
            <div className='actionsStructure'>
                <RiMedalLine
                    className='_actionButton action'
                    data-tip='Changer le rang'
                    data-for='changeRank'
                    onClick={() => null}
                />
                <Tooltip id='changeRank'/>
                <GiStairsGoal
                    className='_actionButton action'
                    data-tip='Gestion des objectifs'
                    data-for='objectivesGestion'
                    onClick={() => null}
                />
                <Tooltip id='objectivesGestion'/>
            </div>
        </div>
    );
};

export default Editor;
