import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, IActiveModalState, selectActiveModal } from '../../store/slices/activeModalSlice';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import SplitText from '../SplitText/SplitText';
import './Modal.scss';

const ModalInstanciateMode: React.FC<{ onModalValidation: (redirection: string) => void }> = props => {
    // Use of hooks
    const [isOpen, setIsOpen] = useState<boolean|null>(null);
    const modalNameRef = useRef<string>('instanciateMode');

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const activeModal: IActiveModalState|null = useSelector(selectActiveModal);

    // useEffect when component is mounting
    // Whenever activeModal redux state change
    useEffect(() => {
        if (null !== activeModal) {
            // Verify if it concerns this modal
            setIsOpen(modalNameRef.current === activeModal.name);
        }
    }, [activeModal])

    // Callback used when we need to close the modal
    const handleCloseModal = useCallback(() => {
        // Close modal using redux state
        dispatch(closeModal());
    }, [dispatch])

    // Callback used when we need to validate the modal
    const handleValidateModal = useCallback(() => {
        if (activeModal) {
            // Trigger onModalValidation callback given in props
            props.onModalValidation(activeModal.params);
            // Callback to properly close modal
            handleCloseModal();
        }
    }, [handleCloseModal, activeModal, props])

    return (
        <Modal
            isOpen={isOpen ?? false}
            overlayClassName='overlay'
            className='modalInstanciateMode'
        >
            <div className='header'>
                <SplitText
                    className='title'
                    text='Attention à vos choix !'
                    secondHalfColor='white'
                />
                <ImCross className='close' onClick={handleCloseModal}/>
            </div>
            <div className='body'>
                <p>Vous êtes sur le point de quitter la page courante.</p>
                <p>Toutes les données actuellement renseignées et non sauvegardées seront perdues.</p>
                <p>Êtes-vous sûr de vouloir quitter ?</p>
            </div>
            <div className='footer'>
                <button className='cancel' onClick={handleCloseModal}>Annuler</button>
                <button className='validate' onClick={handleValidateModal}>Valider</button>
            </div>
        </Modal>
    );
};

export default ModalInstanciateMode;
