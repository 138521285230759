import React, { useEffect, useState } from 'react';
import SiteAPIs from '../../APIs/SiteAPIs';
import ISite from '../../interfaces/ISite';
import IWordPress from '../../interfaces/IWordPress';
import IWordPressCategory from '../../interfaces/IWordPressCategory';

const CategoryOptions: React.FC<{ siteId: number|string }> = props => {
    // Use of hooks
    const [wordPress, setWordPress] = useState<IWordPress|null>(null);

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve data about Site
        SiteAPIs.getSites({ id: props.siteId })
        // On successful call, store Sites in hook
        .then((data: Array<ISite>) => data && setWordPress(data[0].wordPress));
    }, [props.siteId])

    return (
        <>
            {
                null !== wordPress && (
                    wordPress.wordPressCategories.map((wpCategory: IWordPressCategory, index: number) => (
                        <option key={index} value={wpCategory.category.id}>{wpCategory.category.name}</option>
                    ))
                )
            }
        </>
    );
};

export default CategoryOptions;
