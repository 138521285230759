import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Tools from '../../helpers/Tools';
import ImagePreview from '../ImagePreview/ImagePreview';
import variablesCSS from '../../styles/exports.module.scss';
import './ImageDropzone.scss';

// Styled DropzoneContainer
const DropzoneContainer: any = styled.div<any>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width ?? '100%'};
    height: 100%;
    padding: 2vh 2%;
    margin: ${props => props.margin ?? '10px 0'};
    border: 2px dashed ${props => Tools.getImageDropzoneColor(props)};
    border-radius: ${variablesCSS.borderRadius};
    cursor: pointer;
    transition: ${variablesCSS.transition};
    &:hover {
        border-color: ${variablesCSS.fontColor};
    }
`;

const ImageDropzone: React.FC<{
    onDrop: (image: File|null) => void,
    initialImage?: string|File|null|undefined,
    width?: string|undefined,
    margin?: string|undefined
}> = props => {
    // Use of hooks
    const [mounted, setMounted] = useState<boolean>(false);
    const [image, setImage] = useState<File|null>(null);
    const [imageSrc, setImageSrc] = useState<string|null>(null);

    // Callback used once file is dropped
    const onDrop = useCallback((acceptedImage: Array<File>) => {
        // Retrieve the image dropped
        const droppedImage: File = acceptedImage[0];

        // If file match acceptedExtensions, store this new local url in our hook
        undefined !== droppedImage && setImage(droppedImage);
    }, [])

    // Use of react-dropzone hook
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, accept: '.jpeg,.jpg,.png', maxFiles: 1 });

    // useEffect when component is mounting
    useEffect(() => {
        if (props.initialImage && !mounted) {
            // Trigger use effect once
            setMounted(true);

            'string' === typeof props.initialImage ?
                null === imageSrc && setImageSrc(props.initialImage)
                : null === image && setImage(props.initialImage);
        }
    }, [props.initialImage, mounted, image, imageSrc])

    // Callback used when ImagePreview is closed
    const handleClosePreview = useCallback(() => {
        // Give null as param for props callback
        props.onDrop(null);
        // Reset image hook value
        setImage(null);
        // Close ImagePreview by setting hook value to null
        setImageSrc(null);
    }, [props])

    // useEffect each time image changes we trigger onDrop props callback
    useEffect(() => {
        if (null !== image && null === imageSrc) {
            console.log('preview')
            // Create local url to enable a preview
            const localUrl = URL.createObjectURL(image);

            // Give image as param for props callback
            props.onDrop(image);
            // Store this local url in our hook
            setImageSrc(localUrl);
        }
    }, [props, image, imageSrc]);

    // useEffect triggered each time imageSrc hook value changes
    useEffect(() => {
        // Revoke local url associated to imageSrc to prevent memory leaks
        null !== imageSrc && URL.revokeObjectURL(imageSrc);
    }, [imageSrc])

    return (
        null === imageSrc ? (
            <DropzoneContainer { ...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
                width: props.width,
                margin: props.margin
            }) }>
                <input { ...getInputProps() } />
                <p className='imageDropzoneDesc'>
                    {`Glissez déposez une image ici, ou cliquez pour sélectionner une image`}
                    <span className='format'>{`(Image au format *.jpeg *.jpg ou *.png)`}</span>
                </p>
            </DropzoneContainer>
        ) : (
            <ImagePreview
                image={imageSrc}
                onClose={() => handleClosePreview()}
            />
        )
    );
};

export default ImageDropzone;
