import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import SplitText from '../SplitText/SplitText';
import './FormikCheckInput.scss';

// Styled container of component
const StyledContainer: any = styled.div<{ width?: string|undefined }>`
    width: ${props => props.width ?? '100%'};
`;

const FormikCheckInput: React.FC<{
    id: string,
    type: 'checkbox'|'radio',
    name: string,
    label: string,
    value?: string|undefined,
    asField?: boolean|undefined
    width?: string|undefined
    disabled?: boolean|undefined
    splitLabel?: boolean|undefined
    splitLength?: number|undefined
    firstHalfColor?: string|undefined,
    secondHalfColor?: string|undefined
}> = props => {
    return (
        <StyledContainer
            className={`formikCheckInputComponent ${props.asField ? 'formikCheckInputAsField' : ''}`}
            width={props.width}
        >
            <Field
                id={props.id}
                className='input'
                type={props.type}
                name={props.name}
                value={props.value}
                disabled={props.disabled ?? false}
            />
            <label className='labelStructure' htmlFor={props.id}>
                <span className='fakeInput' />
                {
                    props.splitLabel ? (
                        <SplitText
                            className='label'
                            text={props.label}
                            length={props.splitLength}
                            firstHalfColor={props.firstHalfColor}
                            secondHalfColor={props.secondHalfColor}
                        />
                    ) : (
                        <p className='label'>{props.label}</p>
                    )
                }
            </label>
        </StyledContainer>
    );
};

export default FormikCheckInput;
