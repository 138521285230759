import React from 'react';
import styled from 'styled-components';
import Tools from '../../helpers/Tools';
import ProgressBar from '../ProgressBar/ProgressBar';
import './ObjectiveProgressBar.scss';

// Label of progress bar
const LabelProgressBar: any = styled.p<{ color: string }>`
    font-weight: bold;
    color: ${props => props.color ?? ''};
`;

const ObjectiveProgressBar: React.FC<{ current: number, aim: number }> = props => {
    // Determine objective percent
    const color: string = Tools.getObjectiveColor(props.current, props.aim);

    return (
        <div className='objectiveProgressBar'>
            <LabelProgressBar
                className='progress'
                color={color}
            >
                {`${props.current} / ${props.aim}`}
            </LabelProgressBar>
            <ProgressBar
                current={props.current}
                aim={props.aim}
                color={color}
            />
        </div>
    );
};

export default ObjectiveProgressBar;
