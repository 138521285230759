import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import rolesHierarchy from '../configs/rolesHierarchy';
import IUserCookie from '../interfaces/IUserCookie';
import IUser from '../interfaces/IUser';

class CookieManager {
    /**
     * Private cookie property
     */
    private cookie: Cookies;

    /**
     * CookieManager constructor
     */
    constructor() {
        this.cookie = new Cookies();
    }

    /**
     * Convert User data to a cookie user data
     * 
     * @param data
     */
    public extractUserCookieData(data: IUser): IUserCookie {
        return {
            id: data.id,
            name: data.name,
            lastname: data.lastname,
            password: data.password,
            mainRole: data.roles.filter(role => role !== 'ROLE_USER')[0],
            roles: this.getUserRoles(data.roles),
            apiKey: data.apiKey,
            connectionAt: data.connectionAt ?? moment('2000-01-01').format('YYYY-MM-DDTHH:mm:ss')
        }
    }

    /**
     * Retrieve apiKey from user cookie
     */
    public getUserApiKey(): string|null {
        const userCookie = this.cookie.get('user');

        return userCookie ? userCookie.apiKey : null;
    }

    /**
     * Retrieve all roles of the user (considering hierarchy)
     */
    private getUserRoles(roles: Array<string>): Array<string> {
        // Retrieve main role (highter one)
        let allRoles = roles.filter(role => role !== 'ROLE_USER');

        // rolesHierarchy beeing an object, we need its entries to loop
        // Add subsequent roles in array of user roles
        Object.entries(rolesHierarchy).forEach(([key, value]) => {
            if (allRoles.includes(key)) {
                allRoles = [ ...allRoles, ...value ];
            }
        });

        // Filter unique
        return allRoles.filter((value: string, index: number, acc: Array<string>) => acc.indexOf(value) === index);
    }
};

export default new CookieManager();
