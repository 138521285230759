import React, { useCallback, useEffect, useState } from 'react';
import { TiArrowLeftThick, TiArrowRightThick } from 'react-icons/ti';
import './ImageSelector.scss';

const ImageSelector: React.FC<{
    images: Array<string>,
    onChange: (image: string) => void,
    startingIndex?: number|undefined,
    className?: string|undefined
}> = props => {
    // Use of hooks
    const [index, setIndex] = useState<number|null>(null);

    // useEffect each time props startingIndex changes
    useEffect(() => {
        null === index && setIndex(props.startingIndex ?? 0);
    }, [index, props.startingIndex])

    // Callback to handle carousel controls
    const handleSlide = useCallback((inc: boolean) => {
        if (null !== index) {
            let newIndex = index;

            // Logic to loop on carousel
            if (inc) {
                newIndex = newIndex + 1 >= props.images.length ? 0 : newIndex + 1;
            } else {
                newIndex = newIndex - 1 < 0 ? props.images.length - 1 : newIndex - 1;
            }

            // Set new index in hook
            setIndex(newIndex);
        }
    }, [index, props])

    // useEffect each time index hook value changes
    useEffect(() => {
        null !== index && props.onChange(props.images[index]);
    }, [props, index])

    return (
        <div className={`imageSelectorComponent ${props.className ?? ''}`}>
            {
                null !== index && (
                    <>
                        <TiArrowLeftThick className='arrow' onClick={() => handleSlide(true)}/>
                                <img className='image' src={props.images[index]} alt='' />
                        <TiArrowRightThick className='arrow' onClick={() => handleSlide(false)}/>
                    </>
                )
            }
        </div>
    );
};

export default ImageSelector;
