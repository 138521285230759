import IRolesHierarchy from '../interfaces/IRolesHierarchy';

// Define roles hierarchy
const rolesHierarchy: IRolesHierarchy = {
    ROLE_DEV: ['ROLE_SUPER_ADMIN'],
    ROLE_SUPER_ADMIN: ['ROLE_ADMIN'],
    ROLE_ADMIN: ['ROLE_MODO', 'ROLE_REF'],
    ROLE_REF: ['ROLE_REDAC'],
    ROLE_MODO: ['ROLE_REDAC']
};

export default rolesHierarchy;
