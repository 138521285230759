import React, { useCallback, useEffect, useState, useRef } from 'react';
import useInterval from '../../hooks/useInterval';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { ITextEditorState, selectTextEditor } from '../../store/slices/textEditorSlice';
import { selectPostStatusBySlug } from '../../store/slices/postStatusesSlice';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik, FormikValues, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import axios from 'axios';
import Tools from '../../helpers/Tools';
import SplitText from '../SplitText/SplitText';
import Checkbox from '../Checkbox/Checkbox';
import PixabaySelector from '../PixabaySelector/PixabaySelector';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import TipTap from '../TipTap/TipTap';
import OptimizationData from '../OptimizationData/OptimizationData';
import PostAPIs from '../../APIs/PostAPIs';
import UploadAPIs from '../../APIs/UploadAPIs';
import IPost from '../../interfaces/IPost';
import IFileUpload from '../../interfaces/IFileUpload';
import IPostStatus from '../../interfaces/IPostStatus';

const EditPostForm: React.FC<{ post: IPost, asAdmin?: boolean|undefined }> = props => {
    // Use of hooks
    const [ownImage, setOwnImage] = useState<boolean|null>(null);
    const [image, setImage] = useState<File|string|null|undefined>(undefined);
    const [imageFile, setImageFile] = useState<File|Blob|null|undefined>(undefined);
    const [formikValues, setFormikValues] = useState<FormikValues|null>(null);
    const formikRef = useRef<FormikProps<FormikValues>|null>(null);

    // Use of cookie
    const [cookie] = useCookies(['user']);

    // Use of react-router-dom
    const history = useHistory();

    // Use of redux
    const textEditorState: ITextEditorState = useSelector(selectTextEditor);
    const unverifiedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('unverified'));
    const savedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('saved'));

    // Define useful constantes
    const postKeyword = props.post.keyword ?? props.post.params.keywordPost;
    const aimScore: number = parseInt(props.post.params.optimization.replace('%', ''));
    const minTitle: number = parseInt(`${process.env.REACT_APP_MIN_TITLE}`);
    const maxTitle: number = parseInt(`${process.env.REACT_APP_MAX_TITLE}`);
    const minMetaDesc: number = parseInt(`${process.env.REACT_APP_MIN_META_DESC}`);
    const maxMetaDesc: number = parseInt(`${process.env.REACT_APP_MAX_META_DESC}`);

    // Validation scheme of the formik form
    let validationSchema = Yup.object({
        title: Yup
            .string()
            .required("Veuillez spécifier le titre de l'article")
            .test(
                'startsWith',
                `Le titre de l'article doit commencer par ${postKeyword} :`,
                (title: string|undefined) => 
                    null === props.post.params.title ?
                       undefined === title || title.startsWith(`${postKeyword} :`)
                       : true
            )
            .test(
                'minLength',
                `Le nombre minimum de caractéres est fixé à ${minTitle}`,
                (title: string|undefined) => 
                    null === props.post.params.title ?
                       undefined === title || title.trim().length >= minTitle
                       : true
            )
            .test(
                'maxLength',
                `Le nombre maximum de caractéres est fixé à ${maxTitle}`,
                (title: string|undefined) => 
                    null === props.post.params.title ?
                       undefined === title || title.trim().length <= maxTitle
                       : true
            ),
        metaDesc: Yup
            .string()
            .required("Veuillez spécifier la meta description de l'article")
            .test(
                'minLength',
                `Le nombre minimum de caractéres est fixé à ${minMetaDesc}`,
                (metaDesc: string|undefined) => 
                    null === props.post.params.metaDesc ?
                       undefined === metaDesc || metaDesc.trim().length >= minMetaDesc
                       : true
            )
            .test(
                'maxLength',
                `Le nombre maximum de caractéres est fixé à ${maxMetaDesc}`,
                (metaDesc: string|undefined) => 
                    null === props.post.params.metaDesc ?
                       undefined === metaDesc || metaDesc.trim().length <= maxMetaDesc
                       : true
            ),
        altText: Yup
            .string()
            .required("Veuillez spécifier un texte alternatif pour l'image")
            .test(
                'includes',
                `Le texte alternatif doit contenir le mot-clé de l'article`,
                (altText: string|undefined) => undefined === altText || altText.toLowerCase().includes(postKeyword.toLowerCase())
            )
    });

    // useEffect when component is mounting
    useEffect(() => {
        // Initialize ownImage hook value
        null === ownImage && setOwnImage((props.post.content && props.post.content.ownImage) ? props.post.content.ownImage : false);
        // Initialize image hook value
        undefined === image && setImage((props.post.content && props.post.content.image) ? props.post.content.image.url : null);
        // Initialize formik values hook
        null === formikValues && setFormikValues({
            keywordPost: postKeyword,
            type: props.post.postType.name,
            title: props.post.params.title ?? (props.post.content ? props.post.content.title : ''),
            metaDesc: props.post.params.metaDesc ?? (props.post.content ? props.post.content.metaDesc : ''),
            altText: (props.post.content && props.post.content.altText) ? props.post.content.altText : postKeyword
        });
    }, [ownImage, image, formikValues, postKeyword, props.post])

     // Callback used to handle selected tool to choice Post image
     const handleImageCheckboxes = useCallback(() => {
        // Invert checkboxes
        setOwnImage(!ownImage);
        // reset selected image
        setImage(null);
    }, [ownImage])

    // Callback used to convert selected image to File and store it in hook
    const convertImageToFile = useCallback((image: string|File|null|undefined) => {
        if (undefined !== image && null !== image) {
            typeof image === 'object' ?
                // If it is already a File, we can store it immediately
                setImageFile(image)
                // or create File from Pixabay url
                : axios.get(image, { responseType: 'blob' })
                    .then((response: any) =>
                        // @see https://stackoverflow.com/questions/59465413/convert-blob-url-to-file-object-with-axios
                        setImageFile(new File([response.data], Tools.getRandomImageName(image)))
                    );
        } else {
            // Change imageFile hook from undefined to null
            setImageFile(null);
        }
    }, [])

    // Callback used to save post & stop editing
    const handleSave = useCallback((event: React.MouseEvent<HTMLButtonElement>, values: FormikValues) => {
        // Prevent trigger of submit
        event.preventDefault();

        // If image is already hosted on server
        if (typeof image === 'string' && image.startsWith(`${process.env.REACT_APP_SERVER_URL}`)) {
            // Load most recent formik values & status
            // Also setup image data values
            setFormikValues({ ...values, postStatus: savedStatus!.id, image: props.post.content.image });
        } else {
            // Load most recent formik values & status
            setFormikValues({ ...values, postStatus: savedStatus!.id });
            // Convert selected image to File to upload it afterward
            convertImageToFile(image);
        }
    }, [image, convertImageToFile, props.post.content, savedStatus])

    // Callback used after validation of formikValues
    const customValidation = useCallback(() => {
        // Validate all other values which are not directly related with Formik
        return (
            undefined !== image && null !== image &&
            textEditorState.nbrWords >= props.post.params.nbrWords &&
            null !== textEditorState.score && textEditorState.score >= aimScore
        );
    }, [image, aimScore, textEditorState, props.post])

    // Callback used to submit Formik form
    const handleSubmit = useCallback((values: FormikValues) => {
        // Can not submit without image && not required number of words
        if (customValidation()) {
            // If image is already hosted on server
            if (typeof image === 'string' && image.startsWith(`${process.env.REACT_APP_SERVER_URL}`)) {
                // Load most recent formik values & status
                // Also setup image data values
                setFormikValues({ ...values, postStatus: unverifiedStatus!.id, image: props.post.content.image });
            } else {
                // Load most recent formik values & status
                setFormikValues({ ...values, postStatus: unverifiedStatus!.id });
                // Convert selected image to File to upload it afterward
                convertImageToFile(image);
            }
        }
    }, [image, props.post, unverifiedStatus, convertImageToFile, customValidation])

    // useEffect when submit or save button were clicked & image is not already upload on server
    useEffect(() => {
        if (null !== formikValues && formikValues.hasOwnProperty('postStatus') && undefined !== imageFile) {
            null !== imageFile ?
                // Upload image file on server side
                UploadAPIs.postFile(imageFile)
                .then((data: IFileUpload) => {
                    // On successful call
                    data && setFormikValues({ ...formikValues, image: data })
                })
                : setFormikValues({ ...formikValues, image: null });

            // Reset image file to be able to retry if previous call failed
            setImageFile(undefined);
        }
    }, [formikValues, imageFile])

    // useEffect triggered by status hook value change, while image hook is an url or null
    useEffect(() => {
        if (null !== formikValues && formikValues.hasOwnProperty('image') && formikValues.hasOwnProperty('postStatus')) {
            // Call our API to save the post
            PostAPIs.putPost(props.post.id, {
                postStatus: formikValues.postStatus,
                content: {
                    title: formikValues.title.trim(),
                    metaDesc: formikValues.metaDesc.trim(),
                    altText: formikValues.altText.trim(),
                    ownImage: ownImage,
                    image: formikValues.image,
                    html: textEditorState.html,
                    nbrWords: textEditorState.nbrWords,
                    score: textEditorState.score
                },
                writtenAt: props.post.writtenAt ? props.post.writtenAt : formikValues.postStatus !== savedStatus!.id ? moment().format('YYYY-MM-DDTHH:mm:ss') : null
            })
            // Once its successfully updated
            .then((data: IPost) => {
                if (data) {
                    // Either redirect to todo posts page or posts gestion page
                    props.asAdmin ?
                        history.push('/posts/gestion')
                        : history.push('/posts/todo');
                }
            });

            // In any cases, reset formikValues status & image fields to be able to retry if previous call failed
            setFormikValues(Object.keys(formikValues).filter((key: string) => key !== 'status' && key !== 'image'));
        }
    }, [formikValues, cookie.user, ownImage, props, textEditorState, savedStatus, history])

    // useInterval for management of auto save
    useInterval(() => {
        // Do not auto save on admin mode
        if (null !== formikRef.current && !props.asAdmin) {
            // Call our API to save the post
            PostAPIs.putPost(props.post.id, {
                postStatus: savedStatus!.id,
                content: {
                    title: formikRef.current!.values.title.trim(),
                    metaDesc: formikRef.current!.values.metaDesc.trim(),
                    altText: formikRef.current!.values.altText.trim(),
                    html: textEditorState.html,
                    nbrWords: textEditorState.nbrWords,
                    score: textEditorState.score
                },
            }).then(() => null);
        }
    }, parseInt(`${process.env.REACT_APP_AUTOSAVE}`) * 1000)

    return (
        <div className='editPostForm'>
            {
                null !== formikValues && (
                    <Formik
                        initialValues={formikValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                    >
                        {(formik: FormikValues) => (
                            <Form className='form' autoComplete='off'>
                                <fieldset className='fieldset'>
                                    <div className='_semiField'>
                                        <SplitText className='_label' text="Mot-clé de l'article" />
                                        <Field className='_input' type='text' name='keywordPost' disabled />
                                    </div>
                                    <div className='_semiField'>
                                        <SplitText className='_label' text="Type de l'article" />
                                        <Field className='_input' type='text' name='type' disabled />
                                    </div>
                                    <div className='_semiField'>
                                        <SplitText className='_label' text="Titre de l'article" />
                                        <Field
                                            className='_input'
                                            type='text'
                                            name='title'
                                            disabled={null !== props.post.params.title}
                                        />
                                        <ErrorMessage className='_error' component='span' name='title' />
                                    </div>
                                    <div className='_semiField'>
                                        <SplitText className='_label' text="Meta description de l'article" length={2} />
                                        <Field
                                            className='_textarea'
                                            as='textarea'
                                            name='metaDesc'
                                            disabled={null !== props.post.params.metaDesc}
                                        />
                                        <ErrorMessage className='_error' component='span' name='metaDesc' />
                                    </div>
                                    <div className='_semiField'>
                                        <Checkbox
                                            id='bankImage'
                                            label='Choisir une image depuis Pixabay'
                                            checked={null !== ownImage ? !ownImage : false}
                                            splitLabel
                                            splitLength={3}
                                            onChange={() => handleImageCheckboxes()}
                                        />
                                        {
                                            false === ownImage && (
                                                <PixabaySelector
                                                    initialImage={typeof image === 'string' ? image : undefined}
                                                    keyword={props.post.params.keywordPixabay}
                                                    onChange={setImage}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className='_semiField imageSelectField'>
                                        <Checkbox
                                            id='imagePicker'
                                            label='Choisir une image depuis mes fichiers'
                                            checked={ownImage ?? false}
                                            splitLabel
                                            splitLength={3}
                                            onChange={() => handleImageCheckboxes()}
                                        />
                                        {
                                            true === ownImage && (
                                                <ImageDropzone
                                                    initialImage={typeof image === 'string' ? image : undefined}
                                                    onDrop={setImage}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className='_field'>
                                        <SplitText className='_label' text="Texte alternatif de l'image" length={2} />
                                        <Field className='_semiInput' type='text' name='altText' />
                                        <ErrorMessage className='_error' component='span' name='altText' />
                                    </div>
                                    <div className='_field optimizationDataField'>
                                        <OptimizationData aimScore={aimScore} query={postKeyword} />
                                    </div>
                                    <div className='_field textEditorField'>
                                        <SplitText className='_label' text="Contenu de l'article" />
                                        <TipTap
                                            initialHtml={props.post.content ? props.post.content.html : null}
                                            words={props.post.params.nbrWords}
                                        />
                                        {
                                            (textEditorState.nbrWords < props.post.params.nbrWords) && (
                                                <span className='_error'>Le nombre de mots est insuffisant</span>
                                            )
                                        }
                                        {
                                            (null !== textEditorState.score && textEditorState.score < aimScore) && (
                                                <span className='_error'>Le score d'optimisation est insuffisant</span>
                                            )
                                        }
                                    </div>
                                </fieldset>
                                {
                                    true === props.asAdmin ? (
                                        <button className='_button buttonGreen' type='submit'>
                                            <p>Modifier l'article</p>
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className='_button buttonOrange'
                                                onClick={(event) => handleSave(event, formik.values)}
                                            >
                                                <p>Sauvegarder l'article</p>
                                            </button>
                                            <button className='_button buttonGreen' type='submit'>
                                                <p>Valider l'article</p>
                                            </button>
                                        </>
                                    )
                                }
                            </Form>
                        )}
                    </Formik>
                )
            }
        </div>
    );
};

export default EditPostForm;
