import React from 'react';
import DOMPurify from 'dompurify';

const PurifiedHtml: React.FC<{ html: string, className?: string|undefined }> = props => {
    // Apply custom treatments on html before sanitize
    const finalHtml = props.html.replaceAll('<p></p>', '<br/>');

    return (
        <div
            className={`purifiedHtmlComponent ${props.className ?? ''}`}
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(finalHtml, { USE_PROFILES: { html: true } })
            }}
        />
    );
};

export default PurifiedHtml;
