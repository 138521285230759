import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, IActiveModalState, selectActiveModal } from '../../store/slices/activeModalSlice';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import SplitText from '../SplitText/SplitText';
import './Modal.scss';

const ModalConfirmation: React.FC<{ name: string, text: string, onSubmit: CallableFunction }> = props => {
    // Use of hooks
    const [isOpen, setIsOpen] = useState<boolean|null>(null);

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const activeModal: IActiveModalState|null = useSelector(selectActiveModal);

    // useEffect when component is mounting
    // Whenever activeModal redux state change
    useEffect(() => {
        if (null !== activeModal) {
            // Verify if it concerns this modal
            setIsOpen(props.name === activeModal.name);
        }
    }, [activeModal, props.name])

    // Callback used when we need to close the modal
    const handleCloseModal = useCallback(() => {
        // Close modal using redux state
        dispatch(closeModal());
    }, [dispatch])

    // Callback used when we need to validate the modal
    const handleSubmitModal = useCallback(() => {
        if (null !== activeModal) {
            // Trigger onSubmit callback given in props
            props.onSubmit(activeModal.params);
            // Callback to properly close modal
            handleCloseModal();
        }
    }, [activeModal, props, handleCloseModal])

    return (
        <Modal
            isOpen={isOpen ?? false}
            overlayClassName='overlay'
            className='modalConfirmation'
        >
            <div className='header'>
                <SplitText
                    className='title'
                    text='Confirmer mon choix'
                    secondHalfColor='white'
                />
                <ImCross className='close' onClick={handleCloseModal}/>
            </div>
            <div className='body'>
                <p>{props.text}</p>
            </div>
            <div className='footer'>
                <button className='cancel' onClick={handleCloseModal}>Non</button>
                <button className='validate' onClick={handleSubmitModal}>Oui</button>
            </div>
        </Modal>
    );
};

export default ModalConfirmation;
