import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, IActiveModalState, selectActiveModal } from '../../store/slices/activeModalSlice';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import SplitText from '../SplitText/SplitText';
import DataDisplay from '../DataDisplay/DataDisplay';
import PurifiedHtml from '../PurifiedHtml/PurifiedHtml';
import './Modal.scss';

const ModalViewPost: React.FC<{
    onModalSubmit?: ((postId: number, valid: boolean) => void) | undefined,
    splitTitleLength?: number|undefined
}> = props => {
    // Use of hooks
    const [isOpen, setIsOpen] = useState<boolean|null>(null);
    const [hasValidation, setHasValidation] = useState<boolean|null>(null);
    const modalNameRef = useRef<string>('viewPost');

    // Use react-cookie
    const [cookie] = useCookies(['user']);

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const activeModal: IActiveModalState|null = useSelector(selectActiveModal);

    // useEffect when component is mounting
    // Whenever activeModal redux state change
    useEffect(() => {
        if (null !== activeModal) {
            // Verify if it concerns this modal
            setIsOpen(modalNameRef.current === activeModal.name);
        }
    }, [activeModal])

    // Callback used when we need to close the modal
    const handleCloseModal = useCallback(() => {
        // Close modal using redux state
        dispatch(closeModal());
    }, [dispatch])

    // Callback used when we need to submit the modal
    const handleModalSubmit = useCallback((valid: boolean) => {
        if (activeModal && props.onModalSubmit) {
            // Trigger onModalValidation callback given in props
            props.onModalSubmit(activeModal.params.post.id, valid);
            // Callback to properly close modal
            handleCloseModal();
        }
    }, [handleCloseModal, activeModal, props])

    // useEffect when modal has finished mounting
    useEffect(() => {
        if (null === hasValidation && null !== activeModal && null !== activeModal.params && undefined !== activeModal.params.post) {
            // Modal has validation actions ...
            if (
                'unverified' === activeModal.params.post.postStatus.slug &&
                (
                    cookie.user.roles.includes('ROLE_ADMIN') ||
                    activeModal.params.post.owner.id === cookie.user.id ||
                    ((null !== activeModal.params.post.site || activeModal.params.post.params.destination) &&
                    (null !== activeModal.params.post.category || activeModal.params.post.params.category))
                )
            ) {
                // Admin
                // OR Owner of the command
                // OR Post has site & category & unverified
                setHasValidation(true);
            }
        }
    }, [hasValidation, activeModal, cookie.user])

    return (
        isOpen && activeModal && activeModal.params && (
            <Modal
                isOpen={isOpen}
                overlayClassName='overlay'
                className='modalViewPost'
            >
                <div className='header'>
                    <SplitText
                        className='title'
                        text={activeModal.params.withValidation ? "Validation de l'article" : "Consultation de l'article"}
                        secondHalfColor='white'
                        length={props.splitTitleLength}
                    />
                    <ImCross className='close' onClick={handleCloseModal}/>
                </div>
                <div className='body'>
                    <DataDisplay
                        inline
                        className='postData'
                        label="Mot-clé de l'article"
                        data={activeModal.params.post.keyword}
                        fallback={activeModal.params.post.params.keywordPost}
                    />
                    <DataDisplay
                        inline
                        className='postData'
                        label="Titre de l'article"
                        data={activeModal.params.post.content.title}
                        fallback='Pas encore de titre'
                    />
                    <DataDisplay
                        className='postData'
                        label='Meta description'
                        data={activeModal.params.post.content.metaDesc}
                        fallback='Pas encore de meta description'
                    />
                    {
                        activeModal.params.post.content.image && (
                            <img className='postImage' src={activeModal.params.post.content.image.url} alt='Oups ...' />
                        )
                    }
                    <DataDisplay
                        className='postData'
                        label="Texte alternatif de l'image"
                        data={activeModal.params.post.content.altText}
                        fallback='Pas encore de texte alternatif'
                    />
                    <PurifiedHtml
                        className='postContent'
                        html={activeModal.params.post.content.html}
                    />
                </div>
                {
                    true === hasValidation && (
                        <div className='footer'>
                            <button className='cancel' onClick={() => handleModalSubmit(false)}>Refuser</button>
                            <button className='validate' onClick={() => handleModalSubmit(true)}>Valider</button>
                        </div>
                    )
                }
            </Modal>
        )
    );
};

export default ModalViewPost;
