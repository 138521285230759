import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IPostStatusesState, selectPostStatusBySlug, selectPostStatuses } from '../../store/slices/postStatusesSlice';
import SplitText from '../SplitText/SplitText';
import IPostStatus from '../../interfaces/IPostStatus';
import PostStatusTag from '../PostStatusTag/PostStatusTag';

const StatusesFilters: React.FC<{ onFilter: (filters: IPostStatus[]) => void, className?: string|undefined }> = props => {
    // Use of hooks
    const [filters, setFilters] = useState<Array<IPostStatus>|null>(null);
    const [selectedFilters, setSelectedFilters] = useState<Array<IPostStatus>|null>(null);

    // Use of redux
    const postStatuses: IPostStatusesState = useSelector(selectPostStatuses);
    const unassignedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('unassigned'));
    const unverifiedtatus: IPostStatus|null = useSelector(selectPostStatusBySlug('unverified'));

    // useEffect when component is mounting
    useEffect(() => {
        // Load default statuses filters
        null === selectedFilters && null !== unassignedStatus && null !== unverifiedtatus &&
            setSelectedFilters([unassignedStatus, unverifiedtatus]);
    }, [selectedFilters, unassignedStatus, unverifiedtatus])

    // useEffect whenever selected filters hook value changes
    useEffect(() => {
        if (null !== selectedFilters && null !== postStatuses.data) {
            // Only retrieve statuses which are not already selected as filter
            setFilters(postStatuses.data.filter((reduxStatus: IPostStatus) => 
                !selectedFilters.some((statusFilter: IPostStatus) => statusFilter.id === reduxStatus.id))
            );
            // Call props given onFilter callback
            props.onFilter(selectedFilters);
        }
    }, [selectedFilters, postStatuses.data, props])

    // Callback to handle status filter selection
    const handleSelectFilter = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        if (null !== selectedFilters && null !== postStatuses.data) {
            // Declare selected filter status identifier
            const statusId = parseInt(event.currentTarget.value);
            // Retrieve informations about this status
            const targetedStatus = postStatuses.data.find((status: IPostStatus) => status.id === statusId);

            // Add status in hook as selected filter
            setSelectedFilters([ ...selectedFilters, targetedStatus! ])
        }
    }, [selectedFilters, postStatuses.data])

    // Callback to handle click on status filter
    const handleFilterTagClick = useCallback((filterId: number) => {
        // Filter out this filter from hook
        null !== selectedFilters &&
            setSelectedFilters(selectedFilters.filter((filter: IPostStatus) => filter.id !== filterId))
    }, [selectedFilters])

    return (
        <div className={props.className ?? ''}>
            <SplitText text='Filtrer par catégories :' />
            <select className='_select select' value='' onChange={(event) => handleSelectFilter(event)}>
                <option value='' disabled hidden>Ajouter un statut comme filtre ...</option>
                {
                    null !== filters &&
                        filters.map((postStatus: IPostStatus, index: number) => (
                            <option key={index} value={postStatus.id}>{postStatus.name}</option>
                        ))
                }
            </select>
            {
                null !== selectedFilters && (
                    selectedFilters.map((filter: IPostStatus, index: number) => (
                        <PostStatusTag
                            key={index}
                            className='statusFilterTag'
                            postStatus={filter}
                            onClick={handleFilterTagClick}
                        />
                    ))
                )
            }
        </div>
    );
};

export default StatusesFilters;
