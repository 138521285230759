import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITextEditorState, selectTextEditor, setContent } from '../../store/slices/textEditorSlice';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import EditorToolbar from './EditorToolbar';
import './TipTap.scss';

const TipTap: React.FC<{ initialHtml?: string|undefined, words?: number|undefined }> = props => {
    // Use of redux
    const dispatch: any = useDispatch<any>();
    const textEditorState: ITextEditorState = useSelector(selectTextEditor);
    const boldCounter = useRef<number>(0);

    // Max words added with bold mark
    const maxBoldCounter: number = 20;

    // Extensions options
    Link.options.openOnClick = false;
    Link.options.HTMLAttributes = { target: '_blank', rel: 'noopener noreferer' };
    Placeholder.options.placeholder = 'Commencez à rédiger votre article ...';

    // Use titap hook
    const editor: Editor|null = useEditor({
        extensions: [ StarterKit, Placeholder, Underline, Link ],
        content: props.initialHtml ?? '',
        onUpdate({ editor }) {
            // Store in redux state information based on editorState
            dispatch(setContent(editor.getHTML()));
        }
    });

    // useEffect when component is mounting
    useEffect(() => {
        // Load initial editor content in redux state
        null !== editor && dispatch(setContent(editor.getHTML()));
    }, [editor, dispatch])

    // useEffect which allows to add plainText in draft editor from other component interactions
    useEffect(() => {
        // Listen to redux state until there is a text to add
        if (null !== textEditorState.textToAdd && null !== editor) {
            // Increment bold counter
            ++boldCounter.current;

            // Content to insert depends on counter
            let contentToInsert: any = {
                type: 'text',
                text: `${textEditorState.textToAdd} `
            };
            if (boldCounter.current <= maxBoldCounter) {
                contentToInsert = { ...contentToInsert, marks: [{ type: 'bold' }] };
            }

            // Insert the desired plainText following by a space
            // Bold style depends on number of plainText we had this way
            editor.commands.insertContent([contentToInsert]);
        }
    }, [textEditorState.textToAdd, editor])

    return (
        null !== editor ? (
            <div className='tipTapComponent'>
                <EditorToolbar
                    className='tipTapToolbar'
                    editor={editor}
                    words={props.words}
                />
                <EditorContent editor={editor} />
            </div>
        ) : null
    );
};

export default TipTap;
