import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PostAPIs from '../../APIs/PostAPIs';
import Spinner from '../Spinner/Spinner';
import EditPostRecap from './EditPostRecap';
import EditPostForm from './EditPostForm';
import IPost from '../../interfaces/IPost';
import './EditPost.scss';

const EditPost: React.FC<{ asAdmin?: boolean|undefined }> = props => {
    // Use of hooks
    const [post, setPost] = useState<IPost|null>(null);

    // We can use the `useParams` hook here to access the dynamic pieces of the URL.
    const { postId } = useParams<any>();

    // useEffect which take part whenever postId value changes
    useEffect(() => {
        // Call API for our requested Post to get the latest info about it
        PostAPIs.getPosts({ id: postId })
        .then((data: Array<IPost>) => data && setPost(data[0]))
    }, [postId])

    return (
        null !== post ? (
            <>
                <EditPostRecap post={post} />
                <EditPostForm
                    asAdmin={props.asAdmin}
                    post={post}
                />
            </>
        ) : (
            <Spinner extend />
        )
    );
};

export default EditPost;
