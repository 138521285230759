import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, IActiveModalState, selectActiveModal } from '../../store/slices/activeModalSlice';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import UserAPIs from '../../APIs/UserAPIs';
import SplitText from '../SplitText/SplitText';
import Spinner from '../Spinner/Spinner';
import EditorCard from '../EditorCard/EditorCard';
import IUser from '../../interfaces/IUser';
import './Modal.scss';

const ModalEditorPicker: React.FC<{
    title: string,
    error: string,
    onModalValidation: (params: any, userId: number|null|undefined) => void,
    splitTitleLength?: number|undefined
}> = props => {
    // Use of hooks
    const [isOpen, setIsOpen] = useState<boolean|null>(null);
    const [editors, setEditors] = useState<Array<IUser>|null>(null);
    const [selectedEditor, setSelectedEditor] = useState<number|null|undefined>(undefined);
    const modalNameRef = useRef<string>('editorPicker');

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const activeModal: IActiveModalState|null = useSelector(selectActiveModal);

    // useEffect when component is mounting
    // Whenever activeModal redux state change
    useEffect(() => {
        if (null !== activeModal) {
            // Verify if it concerns this modal
            setIsOpen(modalNameRef.current === activeModal.name);
        }
    }, [activeModal])

    // useEffect whenever modal is open & redux state changes
    useEffect(() => {
        true === isOpen &&
            // Retrieve all users with ROLE_REDAC or ROLE_MODO from API
            UserAPIs.getUsers({ roles: 'ROLE_REDAC,ROLE_MODO' })
            // On sucessful call, store these users in hook
            .then((data: Array<IUser>) => data && setEditors(data));
    }, [isOpen])

    // useEffect used to load selected editors
    useEffect(() => {
        // If modal is open & editor not already selected nor loaded
        true === isOpen && undefined === selectedEditor &&
            // And params are loaded from redux store
            null !== activeModal && null !== activeModal.params &&
                // Select editor if given with params, otherwise null
                setSelectedEditor(activeModal.params.user ? activeModal.params.user.id : null);
    }, [isOpen, selectedEditor, activeModal])

    // Callback used when we need to close the modal
    const handleCloseModal = useCallback(() => {
        // Close modal using redux state
        dispatch(closeModal());
        // Reset selected editor
        setSelectedEditor(undefined);
    }, [dispatch])

    // Callback used when we need to validate the modal
    const handleValidateModal = useCallback(() => {
        if (null !== activeModal) {
            // Trigger onModalValidation callback given in props
            props.onModalValidation(activeModal.params, selectedEditor);
            // Callback to properly close modal
            handleCloseModal();
        }
    }, [activeModal, props, selectedEditor, handleCloseModal])

    return (
        <Modal
            isOpen={isOpen ?? false}
            overlayClassName='overlay'
            className='modalEditorPicker'
        >
            <div className='header'>
                <SplitText
                    className='title'
                    text={props.title}
                    secondHalfColor='white'
                    length={props.splitTitleLength}
                />
                <ImCross className='close' onClick={handleCloseModal}/>
            </div>
            <div className='body'>
                <div className='editorsGrid'>
                    {
                        null !== editors && undefined !== selectedEditor ? (
                            editors.map((editor: IUser, index: number) => (
                                <EditorCard
                                    key={index}
                                    selected={editor.id === selectedEditor}
                                    className='editorCard'
                                    editor={editor}
                                    onCardClick={() => setSelectedEditor(selectedEditor === editor.id ? null : editor.id)}
                                />
                            ))
                        ) : (
                            <Spinner size={4} extend />
                        )
                    }
                </div>
            </div>
            <div className='footer'>
                <button className='cancel' onClick={handleCloseModal}>Annuler</button>
                <button className='validate' onClick={handleValidateModal}>Valider</button>
            </div>
        </Modal>
    );
};

export default ModalEditorPicker;
