import React from 'react';
import styled from 'styled-components';
import GridLoader from 'react-spinners/GridLoader';
import variablesCSS from '../../styles/exports.module.scss';

// Styled Container
const Container: any = styled.div<{ fullscreen: boolean|undefined, extend: boolean|undefined, padding: string|undefined }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${props =>
        props.fullscreen ?
            `min-width: 100vw;
            min-height: 100vh;`
        : props.extend &&
            `min-width: 100%;
            min-height: 100%;`
    }
    ${props => props.padding && `padding: ${props.padding};`}
`;

const Spinner: React.FC<{ fullscreen?: boolean|undefined, extend?: boolean|undefined, color?: string|undefined, size?: number|undefined, padding?: string|undefined }> = props => {
    return (
        <Container
            fullscreen={props.fullscreen}
            extend={props.extend}
            padding={props.padding}
        >
            <GridLoader
                size={props.size ?? 10}
                margin={5}
                color={props.color ?? variablesCSS.orangeColor}
            />
        </Container>
    );
};

export default Spinner;
