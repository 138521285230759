import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * PostTypeAPIs class.
 */
export default class PostTypeAPIs {
    /**
     * Get post types
     * 
     * @param query
     */
    public static async getPostTypes(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall('post_types', apiKey, query, false);
    }
};
