import React from 'react';
import SplitText from '../SplitText/SplitText';
import './Checkbox.scss';

const Checkbox: React.FC<{
    id: string,
    label: string,
    checked: boolean,
    onChange: CallableFunction,
    splitLabel?: boolean|undefined,
    splitLength?: number|undefined,
    className?: string|undefined,
    disabled?: boolean|undefined
}> = props => {
    return (
        <div className={`checkboxComponent ${props.className ?? ''}`}>
            <input
                className='input'
                type='checkbox'
                id={props.id}
                disabled={props.disabled ?? false}
                checked={props.checked}
                onChange={() => null}
            />
            <label className='label' htmlFor={props.id} onClick={() => props.onChange()}/>
            {
                props.splitLabel ? (
                    <SplitText
                        className='fakeLabel'
                        text={props.label}
                        length={props.splitLength}
                        onClick={() => props.onChange()}
                    />
                ) : (
                    <p className='fakeLabel' onClick={() => props.onChange()}>
                        {props.label}
                    </p>
                )
            }
        </div>
    );
};

export default Checkbox;
