import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Tools from '../../helpers/Tools';

export interface ITextEditorState {
    score: number|null,
    html: string|null,
    plainText: string|null,
    textToAdd: string|null,
    nbrWords: number
};

const initialState: ITextEditorState = {
    score: null,
    html: null,
    plainText: null,
    textToAdd: null,
    nbrWords: 0
};

export const slice = createSlice({
    name: 'textEditor',
    initialState,
    reducers: {
        setContent: (state: ITextEditorState, action: PayloadAction<string>) => {
            const plainText = Tools.stripTags(action.payload);
            state.html = action.payload
            state.plainText = plainText;
            state.nbrWords = Tools.countWords(plainText);
            // Following is very important as it prevents to loop on useEffect using textToAdd as dependency
            state.textToAdd = null;
        },
        addNewText: (state: ITextEditorState, action: PayloadAction<string>) => {
            state.textToAdd = action.payload;
        },
        setScore: (state: ITextEditorState, action: PayloadAction<number>) => {
            state.score = action.payload;
        },
        resetTextEditorState: (state: ITextEditorState) => {
            state.score = null;
            state.html = null;
            state.plainText = null;
            state.nbrWords = 0;
        }
    }
});

export const { setContent, addNewText, setScore, resetTextEditorState } = slice.actions;

export const selectTextEditor = (state: any) => state.textEditor;

export default slice.reducer;
