import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Api from '../helpers/Api';
import Notifier from '../helpers/Notifier';

/**
 * APIs class.
 */
export default class GenericAPIs {
    /**
     * Get something from API.
     * 
     * @param url
     * @param apiKey
     * @param query
     * @param notifySuccess
     */
    public static async apiGetCall(url: string, apiKey: string|null, query: any = null, notifySuccess: boolean = true) {
        // Call API baseUrl with GET method on /url
        return Api.get(url, { params: query, headers: { 'X-Api-Key': apiKey } })
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

                // If we have a response, we create a success notification and log in console
                notifySuccess && Notifier.createNotification('Successfully called API', msg, 'success', 5000);
                query ?
                    console.log(`${msg} with query :`, query, 'returns: ', response)
                    : console.log(`${msg} returns :`, response);

                // We manage the fact that the response can be null
                null === response.data && Notifier.createNotification('API response issue', `API did not return a single ${url} response`, 'warning');

                // We also return this response
                return response.data;
            })
            .catch((error: any) => {
                // Message for notifier and console
                const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                console.log(`${msg} with query :`, query);

                // If we have an error, we create a danger notification
                Notifier.createNotification('Failed to call API', msg);

                return null;
            });
    }

    /**
     * Delete something from API.
     * 
     * @param url
     * @param apiKey
     * @param notifySuccess
     */
    public static async apiDeleteCall(url: string, apiKey: string|null, notifySuccess: boolean = true) {
        // Call API baseUrl with DELETE method on /url
        return Api.delete(url, { headers: { 'X-Api-Key': apiKey } })
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;
                console.log(msg);

                // If we have a response, we create a success notification and log in console
                notifySuccess && Notifier.createNotification('Successfully called API', msg, 'success', 5000);

                // We also return true as a response
                return true;
            })
            .catch((error: any) => {
                // Message for notifier and console
                const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                console.log(msg);

                // If we have an error, we create a danger notification
                Notifier.createNotification('Failed to call API', msg);

                return null;
            })
    }

    /**
     * Post something on API.
     *
     * @param url
     * @param apiKey
     * @param body
     * @param config
     * @param notifySuccess
     * @param notifyResponseNull
     */
    public static async apiPostCall(url: string, apiKey: string|null, body: any, config: AxiosRequestConfig|undefined = undefined, notifySuccess: boolean = true, notifyResponseNull: boolean = true) {
        // Determine finalConfig
        const finalConfig: AxiosRequestConfig = config ?
            { ...config, headers: { 'X-Api-Key': apiKey } }
            : { headers: { 'X-Api-Key': apiKey } };

        // Call API baseUrl with POST method on /url
        return Api.post(url, body, finalConfig)
            .then((response: AxiosResponse) => {
                return this.manageCallWithBodySuccess(response, body, notifySuccess, notifyResponseNull);
            })
            .catch((error: any) => {
                return this.manageCallWithBodyFail(error, body);
            });
    }

    /**
     * Put something on API.
     *
     * @param resourceUrl
     * @param id
     * @param additionalPath
     * @param body
     * @param apiKey
     * @param notifySuccess
     * @param notifyResponseNull
     */
    public static async apiPutCall(resourceUrl: string, id: number|null, additionalPath: string|null, apiKey: string|null, body: any, notifySuccess: boolean = true, notifyResponseNull: boolean = true) {
        // Customize url depending on params
        const finalUrl = `${resourceUrl}${id ? `/${id}` : ''}${additionalPath ? `/${additionalPath}` : ''}`;

        // Call API baseUrl with PUT method on /url
        return Api.put(finalUrl, body, { headers: { 'X-Api-Key': apiKey } })
            .then((response: AxiosResponse) => {
                return this.manageCallWithBodySuccess(response, body, notifySuccess, notifyResponseNull);
            })
            .catch((error: any) => {
                return this.manageCallWithBodyFail(error, body);
            });
    }

    /**
     * Patch something on API.
     * 
     * @param url
     * @param apiKey
     * @param query
     * @param body
     * @param notifySuccess
     * @param notifyResponseNull
     */
    public static async apiPatchCall(url: string, apiKey: string|null, query: any, body: any, notifySuccess: boolean = true, notifyResponseNull: boolean = true) {
        // Call API baseUrl with PATCH method on /url
        return Api.patch(url, body, { params: query, headers: { 'X-Api-Key': apiKey } })
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

                // If we have a response, we create a success notification and log in console
                notifySuccess && Notifier.createNotification('Successfully called API', msg, 'success', 5000);
                console.log(`${msg} with query :`, query, 'and body', body, 'returns: ', response)

                // We manage the fact that the response can be null
                null === response.data && notifyResponseNull && Notifier.createNotification('API response issue', `API did not return a single ${url} response`, 'warning');

                // We also return this response
                return response.data;
            })
            .catch((error: any) => {
                // Message for console
                const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                console.log(`${msg} with query :`, query, 'with body :', body);

                //We create a danger notification for each errors from our API response
                Notifier.notifyApiErrors(error.response.data.errors);

                return null; 
            })
    }

    /**
     * Define what to do after a successful API call with a body.
     *
     * @param response
     * @param body
     * @param notifySuccess
     * @param notifyResponseNull
     */
    protected static manageCallWithBodySuccess(response: AxiosResponse, body: any, notifySuccess: boolean, notifyResponseNull: boolean) {
        // Message for notifier and console
        const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

        // If we have a response, we create a success notification and log in console
        notifySuccess && Notifier.createNotification('Successfully called API', msg, 'success', 5000);
        console.log(`${msg} with body :`, body, 'returns :', response);

        // We manage the fact that the response can be null
        null === response.data && notifyResponseNull && Notifier.createNotification('API response issue', `API did not return a response`, 'warning');

        // We also return this response
        return response.data;
    }

    /**
     * Define what to do after a fail API call with a body.
     * 
     * @param error
     * @param body
     */
    protected static manageCallWithBodyFail(error: any, body: any) {
        // Declaration of a message
        let msg: string = '';

        // In case, we retrieve an error response
        if (undefined !== error.response) {
            // Message for console
            msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
            // We create a danger notification for each errors from our API response
            Notifier.notifyApiErrors(error.response.data.errors);
        // In case it was an undefined error, something unexpected
        } else {
            msg = 'Something unexpected happenned... For more information, checkout API logs';
            // We create a danger notification
            Notifier.createNotification('Something went wrong', 'Please reload the application by refreshing current page', 'danger', 10000);
        }
        
        console.log(`${msg} with body :`, body);

        return null;
    }
};
