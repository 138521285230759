import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITextEditorState, selectTextEditor, setScore, addNewText } from '../../store/slices/textEditorSlice';
import styled from 'styled-components';
import useInterval from '../../hooks/useInterval';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import IOptimizationData from '../../interfaces/IOptimizationData';
import Spinner from '../Spinner/Spinner';
import variablesCSS from '../../styles/exports.module.scss';
import './OptimizationData.scss';

// Styled score
const StyledScore: any = styled.span<{ valid: boolean }>`
    color: ${props => props.valid ? `${variablesCSS.greenColor}` : `${variablesCSS.redColor}`};
`;

// Styled keyword tag
const StyledKeyword: any = styled.p<{ included: boolean }>`
    ${props => props.included &&
        `background-color: ${variablesCSS.greenColor};
        color: white;`
    }
    border: 1px solid ${props => props.included ? `${variablesCSS.greenColor}` : `${variablesCSS.orangeColor}`};
`;

const OptimizationData: React.FC<{ aimScore: number, query: string }> = props => {
    // Use of hooks
    const [plainText, setPlainText] = useState<string|null>(null);
    const [timer, setTimer] = useState<number|null|undefined>(null);
    const [optiData, setOptiData] = useState<IOptimizationData|null>(null);

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const textEditorState: ITextEditorState = useSelector(selectTextEditor);

    // Define timer between each test
    const intervalOptiData: number = 60;

    // useEffect whenever redux state change
    useEffect(() => {
        // If hook is different to redux state value (when redux state changes)
        // Then, update our hook value to has the same value
        // This prevent useless calls using textEditorState directly as a dependency of useEffect
        plainText !== textEditorState.plainText && setPlainText(textEditorState.plainText);
    }, [plainText, textEditorState.plainText])

    useEffect(() => {
        if (null !== plainText && null === timer) {
            // Close timer
            setTimer(undefined);

            // Call API to retrieve optimization data
            BridgeAPIs.getOptimizationData({ query: props.query, content: plainText})
            // On sucessful API call
            .then((data: IOptimizationData) => {
                if (data) {
                    // Store optimization data in hook
                    0 < Object.values(data.keywords).length && setOptiData(data);
                    setTimer(intervalOptiData);
                }
            });
        }
    }, [props.query, plainText, timer])

    // Callback used to handle click on keyword tag
    const handleKeywordTagClick = useCallback((value: string) => {
        // Inform redux state that we have a content to add in editor state
        dispatch(addNewText(value));
    }, [dispatch])

    // useEffect to store optimization score in redux state
    useEffect(() => {
        // Also store information in textEditor redux state
        null !== optiData && dispatch(setScore(optiData.score));
    }, [optiData, dispatch])

    // useInterval for management of test timer
    useInterval(() => {
        // Decrement timer hook value by 1 every second until it reaches 0
        timer && timer > 0 && setTimer(timer - 1);
    }, 1000)

    const handleAskTest = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        // Prevent default of event
        event.preventDefault();
        // Reset timer to null to make an API call
        setTimer(null);
    }, [])

    return (
        <div className='optimizationDataComponent'>
            <button
                className='askButton'
                type='button'
                onClick={(event) => handleAskTest(event)}
                disabled={0 !== timer}
            >
                <p>{`Tester mon optimisation ${0 === timer || !timer ? '' : `(${timer}s)`}`}</p>
            </button>
            {
                null !== optiData ? (
                    <div className='dataStructure'>
                        <p className='score'>
                            Score d'optimisation:
                            <StyledScore valid={optiData.score >= props.aimScore}>
                                {`${optiData.score}%`}
                            </StyledScore>
                        </p>
                        {
                            0 === optiData.keywords.length ? (
                                <p>Oups ... Impossible de proposer des mots-clés.</p>
                            ) : (
                                <div className='keywordsList'>
                                    {
                                        Object.entries(optiData.keywords).map(([_, value], index: number) => {
                                            const contentLC: string = textEditorState.plainText ?
                                                textEditorState.plainText.toLowerCase() : '';
                                            const valueLC: string = value.toLowerCase();
                                            const included: boolean = contentLC.includes(valueLC);

                                            return (
                                                <StyledKeyword
                                                    key={index}
                                                    className='keywordTag'
                                                    included={included}
                                                    onClick={() => handleKeywordTagClick(valueLC)}
                                                >
                                                    {value}
                                                </StyledKeyword>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                ) : (<Spinner extend size={5} padding='1vh' />)
            }
        </div>
    );
};

export default OptimizationData;
