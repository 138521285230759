import React from 'react';
import { useHistory } from 'react-router';
import Tools from '../../helpers/Tools';
import Tooltip from '../Tooltip/Tooltip';
import { FaFeatherAlt } from 'react-icons/fa';
import PostStatusTag from '../PostStatusTag/PostStatusTag';
import IPost from '../../interfaces/IPost';

const Post: React.FC<{ post: IPost }> = props => {
    // Use of react-router-dom
    const history = useHistory();

    return (
        <div className='postContainer'>
            <div className='post'>
                <div className='statusCell widthStatus'>
                    <PostStatusTag postStatus={props.post.postStatus} />
                </div>
                <p className='cell widthKeyword'>{props.post.keyword ?? props.post.params.keywordPost}</p>
                <p className='cell widthType'>{props.post.postType.name}</p>
                <p className='cell widthWords'>{props.post.params.nbrWords}</p>
                <p className='cell widthOpti'>
                    {'0%' === props.post.params.optimization ? 'Libre' : props.post.params.optimization}
                </p>
                <div className='actionsCell widthActions'>
                    <FaFeatherAlt
                        className='_actionButton'
                        data-tip="Éditer l'article"
                        data-for='editPost'
                        onClick={() => history.push(`/posts/todo/edit/${props.post.id}`)}
                    />
                    <Tooltip id='editPost'/>
                </div>
            </div>
            <div className='datesStructure'>
                {
                    null !== props.post.updatedAt && 'saved' === props.post.postStatus.slug && (
                        <p className='label'>
                            Dernière modification le :
                            <span className='date'>{Tools.humanizeTimestamp(props.post.updatedAt)}</span>
                        </p>
                    )
                }
                <p className='label'>
                    Assigné le :
                    <span className='date'>{Tools.humanizeTimestamp(props.post.assignedAt)}</span>
                </p>
                <p className='label'>
                    Deadline :
                    <span className='date'>{Tools.humanizeTimestamp(props.post.deadlineAt, true)}</span>
                </p>
            </div>
        </div>
    );
};

export default Post;
