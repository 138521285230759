import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IActiveModalState {
    name: string|null,
    params: any
};

interface IOpenModal {
    name: string,
    params: any
}

const initialState: IActiveModalState = {
    name: null,
    params: null
};

export const slice = createSlice({
    name: 'activeModal',
    initialState,
    reducers: {
        openModal: (state: IActiveModalState, action: PayloadAction<IOpenModal>) => {
            state.name = action.payload.name;
            state.params = action.payload.params;
        },
        closeModal: (state: IActiveModalState) => {
            state.name = null;
            state.params = null;
        }
    }
});

export const { openModal, closeModal } = slice.actions;

export const selectActiveModal = (state: any) => state.activeModal;

export default slice.reducer;
