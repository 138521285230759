import { useEffect, useRef } from 'react';

// @see https://usehooks-typescript.com/react-hook/use-interval
function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    // Set up the interval
    useEffect(() => {
        // Don't schedule if no delay is specified
        if (delay === null) {
            return;
        }

        // Setup interval
        const id = setInterval(() => savedCallback.current(), delay);

        // Clear interval if component unmount
        return () => clearInterval(id);
    }, [delay])
};

export default useInterval;
