import React from 'react';
import moment from 'moment-timezone';
import { Switch, Route } from 'react-router-dom';
import Modal from 'react-modal';
import ReactNotification from 'react-notifications-component';
import Header from './Header/Header';
import Login from './Login/Login';
import TodoPosts from './TodoPosts/TodoPosts';
import OwnedCommands from './OwnedCommands/OwnedCommands';
import EditPost from './EditPost/EditPost';
import PostsGestion from './PostsGestion/PostsGestion';
import EditorsGestion from './EditorsGestion/EditorsGestion';
import CommandCreation from './CommandCreation/CommandCreation';
import EditSite from './EditSite/EditSite';
import Spinner from './Spinner/Spinner';
import RedacTool from './RedacTool/RedacTool';

const App: React.FC = () => {
    // Define App element for Modals
    // http://reactcommunity.org/react-modal/accessibility/
    Modal.setAppElement('#root');

    // Set default moment timezone globally
    moment.tz.setDefault('Europe/Paris');

    return (
        <>
            <ReactNotification />
            <Switch>
                <Route exact path='/'>
                    <Login />
                </Route>
                <Route exact path='/posts/todo'>
                    <Header />
                    <section className='_content'>
                        <TodoPosts />
                    </section>
                </Route>
                <Route exact path='/posts/todo/edit/:postId'>
                    <Header />
                    <section className='_content'>
                        <EditPost />
                    </section>
                </Route>
                <Route exact path='/posts/todo/edit/admin/:postId'>
                    <Header />
                    <section className='_content'>
                        <EditPost asAdmin />
                    </section>
                </Route>
                <Route exact path='/posts/gestion'>
                    <Header />
                    <section className='_contentNoScroll'>
                        <PostsGestion />
                    </section>
                </Route>
                <Route exact path='/editors/gestion'>
                    <Header />
                    <section className='_content'>
                        <EditorsGestion />
                    </section>
                </Route>
                <Route exact path='/sites/create'>
                    <Header />
                    <section className='_content'>
                        <EditSite />
                    </section>
                </Route>
                <Route exact path='/commands/owned'>
                    <Header />
                    <section className='_content'>
                        <OwnedCommands />
                    </section>
                </Route>
                <Route exact path='/commands/creation'>
                    <Header />
                    <section className='_content'>
                        <CommandCreation />
                    </section>
                </Route>
                <Route exact path='/redac_tool'>
                    <Header />
                    <section className='_content'>
                        <RedacTool />
                    </section>
                </Route>
                <Route path='/'>
                    <Header />
                    <section className='_contentCenter'>
                        <Spinner padding='0 0 2vh 0'/>
                        <p>Cette fonctionnalité arrive prochainement ;)</p>
                    </section>
                </Route>
            </Switch>
        </>
    );
};

export default App;
