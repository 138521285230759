import React, { useCallback } from 'react';
import styled from 'styled-components';
import Splitter from '../../helpers/Splitter';
import ISplitText from '../../interfaces/ISplitText';
import variablesCSS from '../../styles/exports.module.scss';

const StyledSplitText: any = styled.p<{ color: string|undefined }>`
    color: ${props => props.color ?? variablesCSS.orangeColor};
`;

const StyledSplitTextSpan: any = styled.span<{ color: string|undefined }>`
    color: ${props => props.color ?? variablesCSS.fontColor};
`;

const SplitText: React.FC<{
    text: string,
    firstHalfColor?: string|undefined,
    secondHalfColor?: string|undefined,
    length?: number|undefined,
    className?: string|undefined
    onClick?: CallableFunction|undefined
}> = props => {
    // Split text using Splitter
    const splitText: ISplitText = Splitter.splitString(props.text, 'first', props.length);

    // Callback to trigger props callback on click
    const handleClick = useCallback(() => {
        props.onClick && props.onClick();
    }, [props])

    return (
        <StyledSplitText
            className={props.className ?? ''}
            color={props.firstHalfColor}
            onClick={() => handleClick()}
        >
            {splitText.firstHalf}
            <StyledSplitTextSpan className={props.className ?? ''} color={props.secondHalfColor}>
                {` ${splitText.secondHalf}`}
            </StyledSplitTextSpan>
        </StyledSplitText>
    );
};

export default SplitText;
