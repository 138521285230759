import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ITextEditorState, selectTextEditor } from '../../store/slices/textEditorSlice';
import { Editor } from '@tiptap/react';
import {
    FaBold,
    FaItalic,
    FaUnderline,
    FaList,
    FaListOl,
    FaUndo,
    FaRedo,
    FaLink,
    FaUnlink,
    FaRegCopy,
    FaHtml5
} from 'react-icons/fa';

const EditorToolbar: React.FC<{ editor: Editor, words?: number|undefined, className?: string|undefined }> = props => {
    // Use of redux
    const textEditorState: ITextEditorState = useSelector(selectTextEditor);

    // Callback used to insert link on selection
    const handleSetLink = useCallback(() => {
        // Prompt dialog window to input an url
        const url: string|null = window.prompt('URL cible du lien :');

        // Dialog successful
        url && props.editor.chain().focus().setLink({ href: url }).run()
    }, [props.editor])

    return (
        <div className={props.className ?? ''}>
            <div className='controlsStructure'>
                <FaBold
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleBold().run();
                    }}
                    className={`control ${props.editor.isActive('bold') ? 'isActive' : ''}`}
                />
                <FaItalic
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleItalic().run();
                    }}
                    className={`control ${props.editor.isActive('italic') ? 'isActive' : ''}`}
                />
                <FaUnderline
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleUnderline().run();
                    }}
                    className={`control ${props.editor.isActive('underline') ? 'isActive' : ''}`}
                />
            </div>
            <div className='controlsStructure'>
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleHeading({ level: 2 }).run();
                    }}
                    className={`control ${props.editor.isActive('heading', { level: 2 }) ? 'isActive' : ''}`}
                >
                    <p>h2</p>
                </button>
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleHeading({ level: 3 }).run();
                    }}
                    className={`control ${props.editor.isActive('heading', { level: 3 }) ? 'isActive' : ''}`}
                >
                    <p>h3</p>
                </button>
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleHeading({ level: 4 }).run();
                    }}
                    className={`control ${props.editor.isActive('heading', { level: 4 }) ? 'isActive' : ''}`}
                >
                    <p>h4</p>
                </button>
            </div>
            <div className='controlsStructure'>
                <FaLink
                    onClick={(event) => {
                        event.preventDefault();
                        handleSetLink();
                    }}
                    className={`control ${props.editor.isActive('link') ? 'isActive' : ''}`}
                />
                <FaUnlink
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().unsetLink().run();
                    }}
                    className='control'
                />
            </div>
            <div className='controlsStructure'>
                <FaList
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleBulletList().run();
                    }}
                    className={`control ${props.editor.isActive('bulletList') ? 'isActive' : ''}`}
                />
                <FaListOl
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().toggleOrderedList().run();
                    }}
                    className={`control ${props.editor.isActive('orderedList') ? 'isActive' : ''}`}
                />
            </div>
            <div className='controlsStructure'>
                <FaUndo
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().undo().run();
                    }}
                    className='control'
                />
                <FaRedo
                    onClick={(event) => {
                        event.preventDefault();
                        props.editor.chain().focus().redo().run();
                    }}
                    className='control'
                />
            </div>
            <div className='controlsStructure'>
                <FaRegCopy
                    onClick={(event) => {
                        event.preventDefault();
                        textEditorState.plainText && navigator.clipboard.writeText(textEditorState.plainText);
                    }}
                    className='control'
                />
                <FaHtml5
                    onClick={(event) => {
                        event.preventDefault();
                        textEditorState.html && navigator.clipboard.writeText(textEditorState.html);
                    }}
                    className='control'
                />
            </div>
            {
                props.words ? (
                    <span className='words'>{`Nombre de mots : ${textEditorState.nbrWords} / ${props.words}`}</span>
                ) : (
                    <span className='words'>{`Nombre de mots : ${textEditorState.nbrWords}`}</span>
                )
            }
        </div>
    )
};

export default EditorToolbar;
