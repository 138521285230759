import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * UserObjectiveAPIs class.
 */
export default class UserObjectiveAPIs {
    /**
     * Get user objectives
     * 
     * @param query
     */
    public static async getUserObjectives(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(`users_objectives`, apiKey, query, false);
    }
};
