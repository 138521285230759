import { createAsyncThunk, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import PostStatusAPIs from '../../APIs/PostStatusAPIs';
import IPostStatus from '../../interfaces/IPostStatus';

export interface IPostStatusesState {
    status: boolean|null,
    data: Array<IPostStatus>|null,
    error: string|null
};

const initialState: IPostStatusesState = {
    status: null,
    data: null,
    error: null
};

export const fetchPostStatuses = createAsyncThunk(
    'postStatuses.fetchAll',
    async () => await PostStatusAPIs.getPostStatuses()
);

export const slice = createSlice({
    name: 'postStatuses',
    initialState,
    reducers: {
        resetPostStatuses: (state: IPostStatusesState) => {
            state.status = null;
            state.data = null;
            state.error = null;
        }
    },
    extraReducers: {
        [fetchPostStatuses.fulfilled as any]: (state: IPostStatusesState, action: PayloadAction<Array<IPostStatus>|null>) => {
            state.status = true;
            state.data = action.payload;
        },
        [fetchPostStatuses.rejected as any]: (state: IPostStatusesState, action: any) => {
            state.status = false;
            state.error = action.error.message;
        }
    }
});

export const { resetPostStatuses } = slice.actions;

export const selectPostStatuses = (state: any) => state.postStatuses;

export const selectPostStatusBySlug = (slug: string) => createSelector(
    selectPostStatuses,
    (state: IPostStatusesState) => state.data ?
        // Prevent undefined value
        state.data.find((status: IPostStatus) => status.slug === slug) ?? null
        : null
);

export default slice.reducer;
