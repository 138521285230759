import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * WordPressAPIs class.
 */
export default class WordPressAPIs {
    /**
     * Url on API for the WordPress resource
     */
    private static resourceUrl: string = 'wordpress';

    /**
     * Post a WordPress
     *
     * @param body
     */
    public static async postWordPress(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(this.resourceUrl, apiKey, body, undefined, false);
    }
};
