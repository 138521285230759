import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * CategoryAPIs class.
 */
export default class CategoryAPIs {
    /**
     * Url on API for the Category resource
     */
    private static resourceUrl: string = 'categories';

    /**
     * Get all Categories
     * 
     * @param query
     */
    public static async getCategories(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(this.resourceUrl, apiKey, query, false);
    }

    /**
     * Post a Category
     * 
     * @param body
     */
     public static async postCategory(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(this.resourceUrl, apiKey, body, undefined, false);
    }
};
