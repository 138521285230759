import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import variablesCSS from '../../styles/exports.module.scss';
import './ImagePreview.scss';

// Styled ImagePreview
const Container: any = styled.div<{ margin: string|undefined, padding: string|undefined, size: string|undefined }>`
    position: relative;
    display: flex;
    align-items: center;
    margin: ${props => props.margin ?? '10px 0'};
    padding: ${props => props.padding ?? '4px'};
    border: 1px solid ${variablesCSS.fontColor};
    border-radius: 2px;
    width: ${props => props.size ?? variablesCSS.previewImageSize};
    height: ${props => props.size ?? variablesCSS.previewImageSize};
    overflow: hidden;
    transition: ${variablesCSS.transition};
`;

const ImagePreview: React.FC<{
    image: string,
    onClose: CallableFunction,
    size?: string|undefined,
    margin?: string|undefined,
    padding?: string|undefined
}> = props => {
    // Use of hooks
    const imagePreviewRef = useRef<HTMLDivElement|null>(null);

    // Callback which handle mouse event on image preview close button
    const handleMouseOnPreviewClose = useCallback((enter: boolean) => {
        if (null !== imagePreviewRef.current) {
            imagePreviewRef.current.style.boxShadow = enter ?
                variablesCSS.boxShadowWhite
                : 'none';
        }
    }, [])

    return (
        <Container
                margin={props.margin}
                padding={props.padding}
                size={props.size}
                ref={imagePreviewRef}
            >
            <img className='imagePreviewImage' src={props.image} alt='Oups ...' />
            <IoMdClose
                className='imagePreviewClose'
                onClick={() => props.onClose()}
                onMouseOver={() => handleMouseOnPreviewClose(true)}
                onMouseLeave={() => handleMouseOnPreviewClose(false)}
            />
        </Container>
    );
};

export default ImagePreview;
