import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * UploadAPIs class.
 */
export default class UploadAPIs {
    /**
     * Upload file on our API
     * 
     * @param file
     */
    public static async postFile(file: File|Blob) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // Setup our body & config
        const formData: FormData = new FormData()
        formData.append('file', file);

        // do not notify on success
        return GenericAPIs.apiPostCall(`upload/files`, apiKey, formData, { headers: { 'content-type': 'multipart/form-data' } }, false);
    }
};
