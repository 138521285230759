import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * PostStatusAPIs class.
 */
export default class PostStatusAPIs {
    /**
     * Get post statuses
     * 
     * @param query
     */
    public static async getPostStatuses(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall('post_statuses', apiKey, query, false);
    }
};
