import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPostStatusBySlug } from '../../store/slices/postStatusesSlice';
import moment from 'moment-timezone';
import { BiSearchAlt2 } from 'react-icons/bi';
import PostAPIs from '../../APIs/PostAPIs';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import Post from './Post';
import Spinner from '../Spinner/Spinner';
import ModalWaiting from '../Modal/ModalWaiting';
import ModalViewPost from '../Modal/ModalViewPost';
import ModalEditorPicker from '../Modal/ModalEditorPicker';
import ModalDatePicker from '../Modal/ModalDatePicker';
import ModalConfirmation from '../Modal/ModalConfirmation';
import StatusesFilters from './StatusesFilters';
import IPost from '../../interfaces/IPost';
import IPostStatus from '../../interfaces/IPostStatus';
import variablesCSS from '../../styles/exports.module.scss';
import './PostsGestion.scss';

const PostsGestion: React.FC = () => {
    // Use of hooks
    const [statusesFilters, setStatusesFilters] = useState<Array<IPostStatus>|null>(null);
    const [termFilter, setTermFilter] = useState<string>('');
    const [posts, setPosts] = useState<Array<IPost>|null>(null);
    const [publishPost, setPublishPost] = useState<IPost|null>(null);
    const timerEditorFilter = useRef<NodeJS.Timeout|null>(null);
    const loadingMore = useRef<boolean>(false);
    const pagination = useRef<number>(1);

    // Use of redux
    const unassignedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('unassigned'));
    const todoStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('todo'));
    const savedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('saved'));
    const verifiedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('verified'));
    const refusedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('refused'));
    const archivedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('archived'));

    // Callback used to reate body for API request from filter
    const constructBodyFromFilters = useCallback(() => {
        // Default body
        let body: Object = { page: pagination.current };

        if (null !== statusesFilters && 0 < statusesFilters.length) {
            // Add statuses filters in body
            body = {
                ...body, 
                postStatus: statusesFilters.map((filter: IPostStatus) => filter.id).join(',')
            };
        }

        if ('' !== termFilter) {
            // Add term filter if specified
            body = { ...body, search: termFilter};
        }

        return body;
    }, [statusesFilters, termFilter])

    // Callback used to update display by retrieving Posts using filters
    const filterPosts = useCallback(() => {
        // Reset pagination
        pagination.current = 1;
        // Reset loading state status
        loadingMore.current = false;

        // Call our API to retrieve Posts using filters
        PostAPIs.getPosts(constructBodyFromFilters())
        // On successful call, store retrieved Posts in hook
        .then((data: Array<IPost>) => setPosts(data));
    }, [constructBodyFromFilters])

    // useEffect whenever statusesFilters hook value changes
    useEffect(() => {
        // Call our API to retrieve Posts using filters
        if (null !== statusesFilters) {
            // Clear previous timeout 
            timerEditorFilter.current && clearTimeout(timerEditorFilter.current);

            // Setup new timeout
            timerEditorFilter.current = setTimeout(() => {
                // Ask to retrieve posts with new filters
                filterPosts();
            }, 1000);
        }
    }, [statusesFilters, termFilter, filterPosts])

    // useEffect will clear timeout when component unmount
    useEffect(() => {
        return () => {
            timerEditorFilter.current && clearTimeout(timerEditorFilter.current);
        };
    }, [])

    // Callback after validation of ModalEditorPicker
    const modalEditorPickerValidation = useCallback((post: IPost, editorId: number|null|undefined) => {
        // No need to call API if editor did not changed
        if (null === post.editor || editorId !== post.editor.id) {
            // Call API to update post with new information
            PostAPIs.putPost(post.id, {
                editor: editorId ?? null,
                postStatus: editorId ?
                    (['todo', 'unassigned'].includes(post.postStatus.slug) ? todoStatus!.id : savedStatus!.id)
                    : unassignedStatus!.id,
                assignedAt: editorId ? moment().format('YYYY-MM-DDTHH:mm:ss') : null,
                writtenAt: null
            })
            // On successful call refresh display
            .then(() => filterPosts());
        }
    }, [todoStatus, savedStatus, unassignedStatus, filterPosts])

    // Callback after validation of ModalDatePicker
    const modalDatePickerValidation = useCallback((post: IPost, deadline: string) => {
        // No need to call API if editor did not changed
        PostAPIs.putPost(post.id, { deadlineAt: deadline })
        // On successful call refresh display
        .then(() => filterPosts());
    }, [filterPosts])

    // Callback after validation or refuse of ModalViewPost
    const modalViewPostValidation = useCallback((postId: number, valid: boolean) => {
        // Request body to post a verified Post
        let body: any = {
            postStatus: verifiedStatus!.id,
            verifiedAt: moment().format('YYYY-MM-DDTHH:mm:ss')
        };

        // Request body to post a refused Post
        if (false === valid) {
            body = {
                postStatus: refusedStatus!.id,
                writtenAt: null
            };
        }

        // Call API to update post with new information
        PostAPIs.putPost(postId, body)
        // On successful API call
        .then((data: IPost) => {
            if (data) {
                // Validation & post is in instant publish mode
                valid && null == data.plannedFor ?
                    // Attemp to publish Post
                    setPublishPost(data)
                    // Update hook value & so re render component to update display
                    : filterPosts()
            }
        });
    }, [verifiedStatus, refusedStatus, filterPosts])

    // Callback after validation to archive Post
    const modalArchiveConfirmation = useCallback((postId: number) => {
        // Call our API to archive the Post
        PostAPIs.putPost(postId, {
            postStatus: archivedStatus!.id,
            archivedAt: moment().format('YYYY-MM-DDTHH:mm:ss')
        })
        //On successful call
        .then((data: IPost) => data && filterPosts());        
    }, [archivedStatus, filterPosts])

    // Callback after validation to delete Post
    const modalDeleteConfirmation = useCallback((postId: number) => {
        // Call our API to delete the Post
        PostAPIs.deletePost(postId)
        //On successful call
        .then(() => filterPosts());   
    }, [filterPosts])

    // useEffect whenever publishPost hook value is no longer null
    useEffect(() => {
        // We have a Post to publish on WordPress
        null !== publishPost &&
            BridgeAPIs.publishPost({ post: publishPost.id })
            // On successful API call
            .then((data: IPost) => {
                if (data) {
                    // Reset hook value to null & wait next Post
                    setPublishPost(null);
                    // Update hook value & so re render component to update display
                    filterPosts();
                }
            });
    }, [publishPost, filterPosts])

    // Callback used to load more posts
    const loadMorePosts = useCallback(() => {
        if (Array.isArray(posts)) {
            // Loading more mean call our API for the next collection resource page
            pagination.current = pagination.current + 1;

            // Call our API to retrieve Posts using filters
            PostAPIs.getPosts(constructBodyFromFilters())
            // On successful call, store retrieved Posts in hook
            .then((data: Array<IPost>) => {
                if (data && 0 < data.length) {
                    setPosts([ ...posts, ...data ]);
                    loadingMore.current = false;
                }
            });
        }
    }, [posts, constructBodyFromFilters])

    // Callback which handle scroll ending on listing to load more (infinite scroll)
    const handleListingScoll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
        // Calculation of scroll distance
        const scrollDist: number = event.currentTarget.scrollHeight - event.currentTarget.scrollTop;

        // loadMorePosts callback can only the first user scroll to the end
        if (false === loadingMore.current && (scrollDist - 400) <= event.currentTarget.clientHeight) {
            // So, we make sure to set a ref for this purpose
            loadingMore.current = true;
            loadMorePosts();
        }
    }, [loadMorePosts])

    return (
        <div className='postsGestion'>
            <ModalWaiting
                isOpen={null !== publishPost}
                title='Publication en cours. Veuillez patientez ...'
            />
            <ModalViewPost onModalSubmit={modalViewPostValidation} />
            <ModalEditorPicker
                title="Assigner l'article à un rédacteur"
                error='Veuillez sélectionner un rédacteur'
                splitTitleLength={2}
                onModalValidation={modalEditorPickerValidation}
            />
            <ModalDatePicker
                title='Nouvelle deadline'
                onModalValidation={modalDatePickerValidation}
            />
            <ModalConfirmation
                name='archiveConfirmation'
                text="Êtes-vous sûr de vouloir archiver l'article ?"
                onSubmit={modalArchiveConfirmation}
            />
            <ModalConfirmation
                name='deleteConfirmation'
                text="Êtes-vous sûr de vouloir supprimer l'article ? Attention, toute suppression est définitive et irréversible donc agissez avec prudence !"
                onSubmit={modalDeleteConfirmation}
            />
            <StatusesFilters className='filterStructure' onFilter={setStatusesFilters} />
            <div className='filterStructure'>
                <BiSearchAlt2 fill={variablesCSS.orangeColor} />
                <input
                    className='input'
                    type='text'
                    placeholder='Trouver rapidement ...'
                    onChange={(event) => setTermFilter(event.currentTarget.value)}
                />
            </div>
            <div className='postsListing' onScroll={(event) => handleListingScoll(event)}>
                {
                    null === posts ? (
                        <Spinner extend />
                    ) : 0 === posts.length ? (
                        <p>Aucun article trouvé ...</p>
                    ) : Array.isArray(posts) && posts.map((post: IPost, index: number) => (
                        <Post key={index} post={post} />
                    ))
                }
            </div>
        </div>
    );
};

export default PostsGestion;
