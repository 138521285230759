import React from 'react';
import { IconType } from 'react-icons';
import './DataDisplay.scss';

const DataDisplay: React.FC<{
    label: string,
    data: any,
    icon?: IconType|undefined,
    inline?: boolean|undefined,
    className?: string|undefined,
    fallback?: string|undefined
}> = props => {
    // Convert IconType to component
    // A component must be in ucfirst format
    const Icon = props.icon ?? null;

    return (
        <div className={`dataDisplayComponent ${props.className ?? ''}`}>
            {
                null !== Icon && (
                    <Icon className='icon' />
                )
            }
            <p className='label'>{props.label} :</p>
            <p className={props.inline ? 'value' : 'element'}>
                {props.data && '' !== props.data ? props.data : props.fallback ?? 'Non renseigné'}
            </p>
        </div>
    );
};

export default DataDisplay;
