import React from 'react';
import Tools from '../../helpers/Tools';
import SplitText from '../SplitText/SplitText';
import DataDisplay from '../DataDisplay/DataDisplay';
import {
    FaKey,
    BsGearFill,
    MdSubject,
    MdDateRange,
    AiOutlineAim,
    AiOutlineFieldNumber,
    IoIosSave,
    IoMdImages
} from 'react-icons/all';
import IPost from '../../interfaces/IPost';

const EditPostRecap: React.FC<{ post: IPost }> = props => {
    return (
        <div className='editPostRecap'>
            <SplitText
                className='title'
                text="Description de l'article"
                secondHalfColor='white'
            />
            <div className='recap'>
                <DataDisplay
                    icon={FaKey}
                    label="Mot-clé de l'article"
                    data={props.post.keyword}
                    fallback={props.post.params.keywordPost}
                />
                <DataDisplay
                    inline
                    icon={AiOutlineAim}
                    label='Type'
                    data={props.post.postType.name}
                />
                <DataDisplay
                    inline
                    icon={IoMdImages}
                    label="Mot-clé de l'image"
                    data={props.post.params.keywordPixabay}
                />
                <DataDisplay
                    icon={MdSubject}
                    label='Consignes à respecter'
                    data={props.post.params.brief}
                    fallback='Pas de consignes particulières'
                />
                <DataDisplay
                    inline
                    icon={AiOutlineFieldNumber}
                    label='Nombre de mots'
                    data={props.post.params.nbrWords}
                />
                <DataDisplay
                    inline
                    icon={BsGearFill}
                    label="Taux d'optimisation"
                    data={props.post.params.optimization}
                />
                <DataDisplay
                    inline
                    icon={MdDateRange}
                    label='Attribution'
                    data={Tools.humanizeTimestamp(props.post.assignedAt, false, 'Pas attribué')}
                />
                <DataDisplay
                    inline
                    icon={MdDateRange}
                    label='Deadline'
                    data={Tools.humanizeTimestamp(props.post.deadlineAt, true)}
                />
                {
                    /** Not available yet */
                    false && (
                        <div className='autoSaveStructure'>
                            <p className='tag'>
                                <IoIosSave className='icon' />
                                Sauvegardé
                            </p>
                            <p className='lastSave'>{`le 21/05/2021 à 15:12`}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default EditPostRecap;
