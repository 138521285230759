import { configureStore } from '@reduxjs/toolkit';
import activeModalReducer from './slices/activeModalSlice';
import textEditorReducer from './slices/textEditorSlice';
import postStatusesReducer from './slices/postStatusesSlice';

export default configureStore({
    reducer: {
        activeModal: activeModalReducer,
        textEditor: textEditorReducer,
        postStatuses: postStatusesReducer
    }
});
