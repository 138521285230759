import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * ThematicAPIs class.
 */
export default class ThematicAPIs {
    /**
     * Url on API for the Thematic resource
     */
    private static resourceUrl: string = 'thematics';

    /**
     * Get all thematics
     * 
     * @param query
     */
    public static async getThematics(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(this.resourceUrl, apiKey, query, false);
    }

    /**
     * Post a Thematic
     * 
     * @param body
     */
     public static async postThematic(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(this.resourceUrl, apiKey, body, undefined, false);
    }
};
