// File giving configurations used with react-router-dom

/*
 * All pathname as key of routerDom will append a goBack nav button in header (also all path under it)
 * It allows the user to go back to previous location
 */

/**
 * All pathname with value 'true' are in instanciate mode (also all path under it)
 * It will open a validation modal whenever you want to leave the location
 */

const routerDom: any = {
    '/posts/todo/edit': true
};

export default routerDom;
