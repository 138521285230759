import IPixabayHit from '../interfaces/IPixabayHit';
import routerDomConfig from '../configs/routerDom';
import variablesCSS from '../styles/exports.module.scss';

/**
 * Tools class.
 */
export default class Tools {
    /**
     * Make a percentage calculation
     * 
     * @param current 
     * @param aim
     */
    public static getPercent(value: number, total: number): number {
        return value * 100 / total;
    }

    /**
     * Convert a ratio to an objective color
     * 
     * @param current
     * @param aim
     */
    public static getObjectiveColor(current: number, aim: number): string {
        const percent = this.getPercent(current, aim);
        let color: string = variablesCSS.fontColor;

        if (75 <= percent) {
            color = variablesCSS.greenColor;
        } else if (percent <= 25) {
            color = variablesCSS.redColor;
        }

        return color;
    }

    /**
     * Convert a DB timestamp to a humanized date
     * 
     * @param timestamp
     * @param withTime
     * @param fallback
     * @param separator
     */
    public static humanizeTimestamp(timestamp: string|null, notWithTime?: boolean|undefined, fallback?: string|undefined, separator?: string|undefined): string {
        // Initialize humanized timestamp
        let result: string = fallback ?? 'Inconnu';

        // Initialize humanized date & time
        let date: string = '';
        let time: string = '';

        if (null !== timestamp) {
            // Split timestamp in two part (date & time)
            const dateFrags: Array<string> = timestamp.split('T');

            // Convert string date to an array of elements, reverse it & join it with a new separator
            // (make an US to EU format date conversion)
            date = dateFrags[0].split('-').reverse().join(separator ?? '/');

            if (!notWithTime) {
                // Retrieve only the interesting part of time (hh:mm)
                time = dateFrags[1].substring(0, 5);
            }

            // Store result
            result = !notWithTime ? `${date} à ${time}` : date;
        }
        
        // Return either result or fallback
        return result;
    }

    /**
     * Define ImageDropzone color from props
     * 
     * @param props
     */
    public static getImageDropzoneColor(props: any): string {
        let color: string = variablesCSS.legendFontColor;

        if (props.isDragAccept) {
            color = variablesCSS.darkGreenColor;
        }
        if (props.isDragReject) {
            color =  variablesCSS.redColor;
        }
        if (props.isDragActive) {
            color = 'white';
        }
        
        return color;
    }

    /**
     * Convert Pixabay hits response to array or urls
     * 
     * @param hitsResponse
     */
    public static convertHitsToUrls(hitsResponse: Array<IPixabayHit>): Array<string> {
        let urls: Array<string> = [];

        hitsResponse.forEach((hit: IPixabayHit) => urls = [ ...urls, hit.webformatURL]);

        return urls;
    }

    /**
     * Count words in string
     * 
     * @param plainText
     */
    public static countWords(plainText: string): number {
        // New line, carriage return, line feed
        const regex = /(?:\r\n|\r|\n)/g;
        // Replace above characters with space
        const cleanString = plainText.replace(regex, ' ').trim();
        // Matches words according to whitespace
        const wordArray = cleanString.match(/\S+/g);

        return wordArray ? wordArray.length : 0;
    }

    /**
     * Determine either location has goBack nav or not
     * 
     * @param pathname
     */
    public static locationWithGoBackNav(pathname: string): boolean {
        // @see src/configs/routerDom
        return Object.keys(routerDomConfig).some((key: string) => pathname.includes(key));
    }

    /**
     * Determine either location is in instaciate mode or not
     * 
     * @param pathname
     */
    public static locationInInstanciateMode(pathname: string): boolean {
        // @see src/configs/routerDom
        return Object.entries(routerDomConfig).some(([key, value]) =>
            pathname.startsWith(key) && true === value
        );
    }

    /**
     * Generate a new random image name
     * 
     * @param imageName
     */
    public static getRandomImageName(imageName: string): string {
        // Retrieve extension from imageName
        const extension = imageName.substr(imageName.lastIndexOf('.') + 1);

        // Generate string of random bytes
        const randomName = Math.random().toString(36).substring(7);

        return `${randomName}.${extension}`;
    }

    /**
     * Make the first letter of string in uppercase
     * 
     * @param string
     */
    public static ucFirst(string : string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * Similar as strip_tags for PHP
     *
     * @param html
     */
    public static stripTags(html: string|null|undefined): string {
        // initialize result
        let clearString: string = '';

        if (html) {
            // Create factice div & insert html
            const div = document.createElement('div');
            div.innerHTML = html.replaceAll('</', ' </');
            clearString = div.textContent || div.innerText || '';
        }

        return clearString.trim();
    }
};
