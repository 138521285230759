import React, { useCallback, useState } from 'react';
import { Formik, Form, Field, FormikValues } from 'formik';
import LoginAPIs from '../../APIs/LoginAPIs';
import ErrorToast from '../Toast/ErrorToast';
import { FaUser, FaLock, FaReact } from 'react-icons/fa';
import { ImEnter } from 'react-icons/im';
import IUser from '../../interfaces/IUser';
import variablesCSS from '../../styles/exports.module.scss';
import './Login.scss';

const LoginForm: React.FC<{ onLoginSuccess: (user: IUser) => void }> = props => {
    // Use of react hooks
    const [loginFailed, setLoginFailed] = useState<boolean>(false);

    // Initial Formik form values
    const initialValues: any = {
        username: '',
        password: ''
    };

    // Callback used to submit Formik form
    const handleSubmit = useCallback((values: FormikValues) => {
        // Call API to login
        LoginAPIs.getLogin({
            username: values.username,
            password: values.password
        })
        // On sucessful call
        .then((data: IUser) => data ? props.onLoginSuccess(data) : setLoginFailed(true))
    }, [props])

    return (
        <div className='loginFormContainer'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form className='form' autoComplete='off'>
                    <FaReact className='logo' />
                    <p className='title'>{`Connexion à ${process.env.REACT_APP_NAME}`}</p>
                    <p className='subTitle'>Veuillez saisir vos identifiants de connexion</p>
                    <fieldset className='fieldset'>
                        <div className='_field'>
                            <p className='_labelContainer'>
                                <FaUser className='_icon' fill={variablesCSS.orangeColor}/>
                                <span className='_label'>Pseudonyme</span>
                            </p>
                            <Field className='_input' type='text' name='username' />
                        </div>
                        <div className='_field'>
                            <p className='_labelContainer'>
                                <FaLock className='_icon' fill={variablesCSS.orangeColor} />
                                <span className='_label'>Mot de passe</span>
                            </p>
                            <Field className='_input' type='password' name='password' autoComplete='on' />
                        </div>
                    </fieldset>
                    <button className='_button' type='submit'>
                        <p>Connexion</p>
                        <ImEnter className='submitIcon' />
                    </button>
                </Form>
            </Formik>
            <ErrorToast
                className='errorToast'
                open={loginFailed}
                message="Nom d'utilisateur ou mot de passe incorrect"
                closable
                onCloseCallback={() => setLoginFailed(false)}
            />
        </div>
    );
};

export default LoginForm;
