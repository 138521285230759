import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectPostStatusBySlug } from '../../store/slices/postStatusesSlice';
import PostAPIs from '../../APIs/PostAPIs';
import ModalViewPost from '../Modal/ModalViewPost';
import Command from './Command';
import Spinner from '../Spinner/Spinner';
import IPost from '../../interfaces/IPost';
import IPostStatus from '../../interfaces/IPostStatus';
import './OwnedCommands.scss';

const OwnedCommands: React.FC<{  }> = props => {
    // Use of hooks
    const [ownedPosts, setOwnedPosts] = useState<Array<IPost>|null>(null);

    // Use react-cookie
    const [cookie] = useCookies(['user']);

    // Use of redux
    const verifiedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('verified'));
    const refusedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('refused'));

    // useEffect when component is mounting
    useEffect(() => {
        // Retrieve owned Posts from API
        null === ownedPosts && PostAPIs.getPosts({ owner: cookie.user.id })
        // On successful call, store OwnedPosts in hook value
        .then((data: Array<IPost>) => data && setOwnedPosts(data));
    }, [cookie.user, ownedPosts])

    // Callback after validation or refuse of ModalViewPost
    const modalViewPostValidation = useCallback((postId: number, valid: boolean) => {
        // Request body to post a verified Post
        let body: any = {
            postStatus: verifiedStatus!.id,
            verifiedAt: moment().format('YYYY-MM-DDTHH:mm:ss')
        };

        // Request body to post a refused Post
        if (false === valid) {
            body = {
                postStatus: refusedStatus!.id,
                writtenAt: null
            };
        }

        // Call API to update post with new information
        PostAPIs.putPost(postId, body)
        // On successful API call
        .then((data: IPost) => data && setOwnedPosts(null));
    }, [verifiedStatus, refusedStatus])

    return (
        null !== ownedPosts ? (
            <>
                {
                    0 < ownedPosts.length ? (
                        <>
                            <ModalViewPost onModalSubmit={modalViewPostValidation} />
                            <div className='ownedCommandsGrid'>
                                {
                                    ownedPosts.map((ownedPost: IPost, index: number) => (
                                        <Command key={index} post={ownedPost}/>
                                    ))
                                }
                            </div>
                        </>
                    ) : (<p>Aucune commande en cours ...</p>)
                }
            </>
        ) : (<Spinner extend />)
    );
};

export default OwnedCommands;
