import ISplitText from '../interfaces/ISplitText';

/**
 * Splitter class.
 */
export default class Splitter {
    /**
     * Either split a string to separate first or last word depending on focus value
     * 
     * @param string 
     * @param focus
     */
    public static splitString(string: string, focus: 'last'|'first', length: number = 1): ISplitText {
        return 'last' === focus ?
                    this.splitLastWords(string, length)
                    : this.splitFirstWords(string, length);
    }

    /**
     * Split a string and separate last words
     * 
     * @param string 
     */
    public static splitLastWords(string: string, lastWordsLength: number = 1): ISplitText {
        const stringArray = string.split(' ');
        const lastWord = stringArray[stringArray.length - 1];

        // Check if we have a punctuation
        if (1 === lastWord.length) {
            // Increase length
            ++lastWordsLength;
        }

        // Return both parts
        return {
            firstHalf: stringArray.slice(0, stringArray.length - lastWordsLength).join(' '),
            secondHalf: stringArray.slice(stringArray.length - lastWordsLength, stringArray.length).join(' ')
        }
    }

    /**
     * Split a string and separate first words
     * 
     * @param string
     */
    public static splitFirstWords(string: string, firstWordsLength: number = 1): ISplitText {
        const stringArray = string.split(' ');

        // Return both parts
        return {
            firstHalf: stringArray.slice(0, firstWordsLength).join(' '),
            secondHalf: stringArray.slice(firstWordsLength, stringArray.length).join(' ')
        }
    }
}
