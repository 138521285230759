import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPostStatuses } from '../../store/slices/postStatusesSlice';
import moment from 'moment-timezone';
import UserAPIs from '../../APIs/UserAPIs';
import LoginAPIs from '../../APIs/LoginAPIs';
import Cookie from '../../helpers/Cookie';
import connectionInterval from '../../configs/connectionInterval';
import BackgroundVideo from '../BackgroundVideo/BackgroundVideo';
import backgroundVideoFile from '../../videos/login_background.mp4';
import LoginForm from './LoginForm';
import Spinner from '../Spinner/Spinner';
import IUser from '../../interfaces/IUser';

const Login: React.FC = () => {
    // Use of react-cookies
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    // Use of react-router-dom
    const history = useHistory();

    // Use of redux
    const dispatch: any = useDispatch<any>();

    // Callback to excute after login success
    const preAppTreatments = useCallback((userId: number) => {
        // Retrieve all PostStatuses from our API and store them in redux state
        dispatch(fetchPostStatuses());
        // Append connection for this User
        LoginAPIs.postConnection({ id: userId })
        // On successful call, navigate to the posts listing page
        .then(() => history.push(`${process.env.REACT_APP_STARTING_PAGE}`));
    }, [history, dispatch])

    // Callback used after successful login form submit
    const createUserCookie = useCallback((user: IUser) => {
        // Create & set user cookie
        setCookie('user', Cookie.extractUserCookieData(user), {
            path: '/',
            expires: moment().add(1, 'week').toDate(),
            secure: true,
            sameSite: 'strict'
        });
    }, [setCookie])

    // Callback used to validate credentials of user cookie
    const validateUserCookie = useCallback(() => {
        // Call API to retrieve user from cookie
        UserAPIs.getUser(cookies.user.id)
        // On successful call
        .then((data: IUser) => {
            // Either remove the unvalid cookie or move to pre-treatments
            data && data.password === cookies.user.password && cookies.user.hasOwnProperty('connectionAt') ?
                preAppTreatments(cookies.user.id) : removeCookie('user');
        });
    }, [cookies.user, preAppTreatments, removeCookie])

    // Callback used to set first connection of day in user cookie
    const modifyUserConnection = useCallback(() => {
        // Moment now date declaration
        const now = moment().format('YYYY-MM-DDTHH:mm:ss');

        // We register his first connection for the day
        UserAPIs.putUser(cookies.user.id, { connectionAt: now })
        .then((data: IUser) => data &&
            // Modify current cookie with new connection
            setCookie('user', { ...cookies.user, connectionAt: now }, {
                path: '/',
                expires: moment().add(1, 'week').toDate(),
                secure: true,
                sameSite: 'strict'
            })
        );
    }, [cookies.user, setCookie])

    // useEffect when component is mounting
    useEffect(() => {
        if (cookies.user) {
            cookies.user.hasOwnProperty('connectionAt') &&
            // Connection after begin connection interval 
            moment().isSameOrAfter(connectionInterval.begin) &&
            // Connection not already set for today
            moment(cookies.user.connectionAt).isBefore(connectionInterval.begin) ?
                // Save this connection
                modifyUserConnection()
                // Try to validate the current user cookie
                // Of course it will fail & remove user cookie
                // Backward compatibility
                : validateUserCookie();
        }
    }, [cookies.user, modifyUserConnection, validateUserCookie])

    return (
        undefined === cookies.user ? (
            <>
                <BackgroundVideo video={backgroundVideoFile} />
                <LoginForm onLoginSuccess={createUserCookie} />
            </>
        ) : <Spinner fullscreen/>
    );
};

export default Login;
