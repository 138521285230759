import React from 'react';
import IPeriodProgress from '../../interfaces/IPeriodProgress';
import ObjectiveProgressBar from '../ObjectiveProgressBar/ObjectiveProgressBar';
import './ObjectiveTable.scss';

const ObjectiveTable: React.FC<{ title: string, data: IPeriodProgress, className?: string|undefined }> = props => {
    return (
        <div className={`objectiveTableComponent ${props.className ?? ''}`}>
            <p className='title'>{props.title}</p>
            <div className='content'>
                <ObjectiveProgressBar
                    current={props.data.today.current}
                    aim={props.data.today.aim}
                />
                <ObjectiveProgressBar
                    current={props.data.month.current}
                    aim={props.data.month.aim}
                />
            </div>
        </div>
    );
};

export default ObjectiveTable;
