import React from 'react';
import ReactTooltip from 'react-tooltip';
import variablesCSS from '../../styles/exports.module.scss';

const Tooltip: React.FC<{ id: string }> = props => {
    // @see https://www.npmjs.com/package/react-tooltip
    // eg. https://wwayne.github.io/react-tooltip/
    return (
        <ReactTooltip
            id={props.id}
            className='tooltipComponent'
            place='bottom'
            textColor='white'
            backgroundColor={variablesCSS.legendFontColor}
        />
    );
};

export default Tooltip;
