import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form, Field, FormikValues, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tools from '../../helpers/Tools';
import SplitText from '../SplitText/SplitText';
import StandaloneInput from '../StandaloneInput/StandaloneInput';
import FormikCheckInput from '../FormikCheckInput/FormikCheckInput';
import ThematicAPIs from '../../APIs/ThematicAPIs';
import CategoryAPIs from '../../APIs/CategoryAPIs';
import WordPressAPIs from '../../APIs/WordPressAPIs';
import SiteAPIs from '../../APIs/SiteAPIs';
import IThematic from '../../interfaces/IThematic';
import IWordPress from '../../interfaces/IWordPress';
import ISite from '../../interfaces/ISite';
import ICategory from '../../interfaces/ICategory';
import './EditSite.scss';

const EditSite: React.FC<{ siteId?: number|undefined }> = props => {
    // Use of hooks
    const [formikValues, setFormikValues] = useState<FormikValues|null>(null);
    const [thematics, setThematics] = useState<Array<IThematic>|null>(null);
    const [siteCreationData, setSiteCreationData] = useState<any|null>(null);
    const [categories, setCategories] = useState<Array<ICategory>|null>(null);
    const [wpCategories, setWpCategories] = useState<Array<number>>([]);

    // Use of react-router-dom
    const history = useHistory();

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        thematic: Yup
            .string()
            .required('Veuillez spécifier une thématique pour votre site'),
        ndd: Yup
            .string()
            .required('Veuillez spécifier une nom de domaine pour votre site'),
        onlineAt: Yup
            .date()
            .test(
                'required',
                'Veuillez spécifier une date de mise en ligne pour votre site',
                function (onlineAt: Date|undefined) {
                    return true === this.parent.siteOnline ? undefined !== onlineAt : true;
                }
            ),
        username: Yup
            .string()
            .test(
                'required',
                'Veuillez spécifier le login WordPress du site',
                function (username: string|undefined) {
                    return true === this.parent.hasWordPress ? undefined !== username : true;
                }
            ),
        passwordWP: Yup
            .string()
            .test(
                'required',
                'Veuillez spécifier le mot de passe WordPress du site',
                function (passwordWP: string|undefined) {
                    return true === this.parent.hasWordPress ? undefined !== passwordWP : true;
                }
            ),
        passwordApp: Yup
            .string()
    });

    // useEffect when component is mounting
    useEffect(() => {
        // Initialize formik values hook
        null === formikValues && setFormikValues({
            thematic: '',
            ndd: '',
            onlineAt: '',
            username: '',
            passwordWP: '',
            passwordApp: '',
            siteOnline: false,
            hasWordPress: false
        });
    }, [props, formikValues])

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve all existing thematics
        ThematicAPIs.getThematics()
        // On successful call, store thematics in hook
        .then((data: Array<IThematic>) => data && setThematics(data));
    }, [])

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve all existing categories
        CategoryAPIs.getCategories()
        // On successful call, store categories in hook
        .then((data: Array<ICategory>) => data && setCategories(data));
    }, [])

    // Callback used to handle creation of a new Thematic (by StandaloneInput)
    const handleAddThematic = useCallback((value: string) => {
        // Call API to create a new thematic only if current hook does not contains it
        null !== thematics &&
            !thematics.some((thematic: IThematic) => thematic.name === Tools.ucFirst(value).trim()) &&
                ThematicAPIs.postThematic({ name: Tools.ucFirst(value).trim() })
                // On successful call, store thematics in hook
                .then((data: IThematic) => data && setThematics([ ...thematics, data ]));
    }, [thematics])

    // Callback used to handle creation of a new Category (by StandaloneInput)
    const handleAddCategory = useCallback((value: string) => {
        // Call API to create a new category only if current hook does not contains it
        null !== categories &&
            !categories.some((category: ICategory) => category.name === value.toLowerCase().trim()) &&
                CategoryAPIs.postCategory({ name: value.toLowerCase().trim() })
                // On successful call, store thematics in hook
                .then((data: ICategory) => data && setCategories([ ...categories, data ]));
    }, [categories])

    // Callback used to create a WordPress resource from formik values
    const createWordPress = useCallback((values: FormikValues) => {
        // Call our API to create a WordPress resource
        WordPressAPIs.postWordPress({
            username: values.username,
            passwordWordPress: values.passwordWP,
            passwordApp: '' !== values.passwordApp ? values.passwordApp : null,
            categories: wpCategories
        })
        // On successful call, modify hook to trigger the site creation useEffect
        .then((data: IWordPress) => data && setSiteCreationData({ ...values, wordPressId: data.id }));
    }, [wpCategories])

    // Callback used to submit Formik form
    const handleSubmit = useCallback((values: FormikValues) => {
        // This condition is enought to determine if we need to create a WordPress relation
        '' !== values.username ?
            // Create WordPress from formik values
            createWordPress(values)
            // Change hook to trigger the site creation useEffect
            : setSiteCreationData(values);
    }, [createWordPress])

    // useEffect which is triggered whenever wordPressCallAPI value changes from undefined
    useEffect(() => {
        if (null !== siteCreationData && null !== thematics) {
            // Call our API to create a Site resource
            SiteAPIs.postSite({
                // Retrieve choosen Thematic ID
                thematic: siteCreationData.thematic,
                wordPress: siteCreationData.hasOwnProperty('wordPressId') ? siteCreationData.wordPressId : null,
                https: true,
                ndd: siteCreationData.ndd.trim(),
                onlineAt: '' !== siteCreationData.onlineAt ? siteCreationData.onlineAt : null
            })
            // On successful call, redirect to Post command page
            .then((data: ISite) => data && history.push('/commands/creation'));

            // In any case, we need to trigger this block once
            setSiteCreationData(null);
        }
    }, [siteCreationData, thematics, history])

    // Callback used to add a WP category
    const handleAddWpCategory = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        // Selected category
        const categoryId = parseInt(event.currentTarget.value);

        // Prevent duplicates
        !wpCategories.find((wpCategory: number) => wpCategory === categoryId) &&
            setWpCategories([ ...wpCategories, categoryId ]);
    }, [wpCategories])

    // Callback used to remove a WP category
    const handleRemoveWpCategory = useCallback((categoryId: number) => {
        // Filter to remove unwanted category
        setWpCategories(wpCategories.filter((wpCategories: number) => wpCategories !== categoryId));
    }, [wpCategories])

    return (
        <>
            <p className='editSiteTitle'>{props.siteId ? `Édition d'un site`: `Ajouter un site`}</p>
            {
                null !== formikValues && (
                    <Formik
                        initialValues={formikValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik: FormikValues) => (
                            <Form className='editSiteForm' autoComplete='off'>
                                <fieldset className='fieldset'>
                                    <div className='_field'>
                                        <SplitText className='_label' text='Thématique du site' />
                                        <Field className='_semiSelect' as='select' name='thematic'>
                                            <option value='' disabled hidden>Sélectionnez une thématique ...</option>
                                            {
                                                null !== thematics && (
                                                    thematics.map((thematic: IThematic, index: number) => (
                                                        <option key={index} value={thematic.id}>{thematic.name}</option>
                                                    ))
                                                )
                                            }
                                        </Field>
                                        <ErrorMessage className='_error' component='span' name='thematic' />
                                    </div>
                                    <div className='_field'>
                                        <SplitText className='_label' text='Ajouter une nouvelle thématique dans la liste' />
                                        <StandaloneInput
                                            semi
                                            className='_input'
                                            placeholder='Nouvelle thématique ...'
                                            error='Veuillez spécifier une nouvelle thématique'
                                            onSubmit={handleAddThematic}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset className='fieldset'>
                                    <div className='_field'>
                                        <SplitText className='_label' text='Nom de domaine du site' length={3} />
                                        <Field
                                            className='_input'
                                            type='text'
                                            placeholder='Inutile de renseigner http:// ou https:// (ex: google.fr)'
                                            name='ndd'
                                        />
                                        <ErrorMessage className='_error' component='span' name='ndd' />
                                    </div>
                                    <FormikCheckInput
                                        asField
                                        id='siteOnline'
                                        type='checkbox'
                                        name='siteOnline'
                                        label='Le site est en ligne'
                                        splitLabel
                                        splitLength={2}
                                    />
                                    {
                                        true === formik.values.siteOnline && (
                                            <div className='_field'>
                                                <SplitText className='_label' text='Date de mise en ligne' />
                                                <Field className='_dateInput' type='date' name='onlineAt' />
                                                <ErrorMessage className='_error' component='span' name='onlineAt' />
                                            </div>
                                        )
                                    }
                                    <FormikCheckInput
                                        asField={true === formik.values.hasWordPress}
                                        id='hasWordPress'
                                        type='checkbox'
                                        name='hasWordPress'
                                        label='Spécifier un WordPress pour le site'
                                        splitLabel
                                    />
                                    {
                                        true === formik.values.hasWordPress && (
                                            <>
                                                <div className='_field'>
                                                    <SplitText className='_label' text='Login du WordPress' />
                                                    <Field className='_semiInput' type='text' name='username' />
                                                    <ErrorMessage className='_error' component='span' name='username' />
                                                </div>
                                                <div className='_field'>
                                                    <SplitText className='_label' text='Mot de passe du WordPress' length={3} />
                                                    <Field className='_semiInput' type='password' name='passwordWP' />
                                                    <ErrorMessage className='_error' component='span' name='passwordWP' />
                                                </div>
                                                <div className='_field'>
                                                    <SplitText className='_label' text="Mot de passe d'application du WordPress" length={3} />
                                                    <Field className='_semiInput' type='password' placeholder='Optionnel' name='passwordApp' />
                                                    <ErrorMessage className='_error' component='span' name='passwordApp' />
                                                </div>
                                                <div className='_semiField'>
                                                    <SplitText className='_label' text='Catégories du WordPress' />
                                                    <div className='wpCategoriesStructure'>
                                                        {
                                                            null !== categories && 0 < wpCategories.length ? (
                                                                wpCategories.map((categoryId: number, index: number) => (
                                                                    <p
                                                                        key={index}
                                                                        className='wpCategory'
                                                                        onClick={() => handleRemoveWpCategory(categoryId)}
                                                                    >
                                                                        {categories.find((category: ICategory) => category.id === categoryId)!.name}
                                                                    </p>
                                                                ))
                                                            ) : (
                                                                <p>Aucune catégorie ...</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='categoriesStructure'>
                                                    <div className='_field'>
                                                        <SplitText className='_label' text='Ajouter une catégorie au WordPress' length={3} />
                                                        <select
                                                            className='_select'
                                                            defaultValue=''
                                                            onChange={(event) => handleAddWpCategory(event)}
                                                        >
                                                            <option value='' disabled hidden>Ajouter une catégorie au WordPress ...</option>
                                                            {
                                                                null !== categories && (
                                                                    categories.map((category: ICategory, index: number) => (
                                                                        <option key={index} value={category.id}>{category.name}</option>
                                                                    ))
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='_field'>
                                                        <SplitText className='_label' text='Créer une nouvelle catégorie' />
                                                        <StandaloneInput
                                                            className='_input'
                                                            placeholder='Ajouter un slug de catégorie ...'
                                                            error='Veuillez spécifier une catégorie'
                                                            onSubmit={handleAddCategory}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </fieldset>
                                <div className='submitStructure'>
                                    <button className='_button' type='submit'>
                                        <p>{props.siteId ? `Modifier le site`: `Ajouter le site`}</p>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )
            }
        </>
    )
};

export default EditSite;
