import React, { useCallback, useState } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import variablesCSS from '../../styles/exports.module.scss';
import './StandaloneInput.scss';

const StandaloneInput: React.FC<{
    placeholder: string,
    error: string,
    onSubmit: (value: string) => void,
    className?: string|undefined
    semi?: boolean|undefined
}> = props => {
    // Use of hooks
    const [inputValue, setInputValue] = useState<string|null>(null);
    const [error, setError] = useState<boolean>(false);

    // Callback used to handle submition of input
    const handleSubmit = useCallback(() => {
        if (!inputValue || '' === inputValue) {
            setError(true);
        } else {
            // Tigger given callback
            props.onSubmit(inputValue);
            setError(false);
            // Reset input value
            setInputValue(null);
        }
    }, [props, inputValue])

    // Callback to manage enter key press in input
    const handleEnterPress = useCallback((event: React.KeyboardEvent) => {
        if ('Enter' === event.key) {
            // Prevent default of event (usefull if input is inside a Formik form)
            event.preventDefault();
            // Simulate a submit
            handleSubmit();
        }
    }, [handleSubmit])

    return (
        <>
            <div className={`${props.semi ? 'semiStandaloneInput ' : ''} standaloneInput`}>
                <input
                    className={`${props.className } input`}
                    type='text'
                    placeholder={props.placeholder}
                    value={inputValue ?? ''}
                    onChange={(event) => setInputValue(event.currentTarget.value)}
                    onKeyDown={(event) => handleEnterPress(event)}
                />
                <IoMdAddCircleOutline
                    className='submit'
                    fill={variablesCSS.orangeColor}
                    onClick={handleSubmit}
                />
            </div>
            {
                error && (
                    <span className='_error'>{props.error}</span>
                )
            }
        </>
    )
};

export default StandaloneInput;
