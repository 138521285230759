import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPostStatusBySlug } from '../../store/slices/postStatusesSlice';
import { useCookies } from 'react-cookie';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import FormikCheckInput from '../FormikCheckInput/FormikCheckInput';
import * as Yup from 'yup';
import PostTypeAPIs from '../../APIs/PostTypeAPIs';
import ThematicAPIs from '../../APIs/ThematicAPIs';
import PostAPIs from '../../APIs/PostAPIs';
import SplitText from '../SplitText/SplitText';
import SiteOptions from './SiteOptions';
import CategoryOptions from './CategoryOptions';
import IPostType from '../../interfaces/IPostType';
import IPostStatus from '../../interfaces/IPostStatus';
import IThematic from '../../interfaces/IThematic';
import './CommandCreation.scss';

const CommandCreation: React.FC = () => {
    // Use of hooks
    const [postTypes, setPostTypes] = useState<Array<IPostType>|null>(null);
    const [thematics, setThematics] = useState<Array<IThematic>|null>(null);
    const [selectedThematic, setSelectedThematic] = useState<string|null>(null);
    const [selectedSite, setSelectedSite] = useState<string|null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string|null>(null);
    const [errorSite, setErrorSite] = useState<boolean>(false);

    // Use of react-router-dom
    const history = useHistory();

    // Use react-cookie
    const [cookie] = useCookies(['user']);

    // Use of redux
    const unassignedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('unassigned'));

    // Define useful constantes
    const minTitle: number = parseInt(`${process.env.REACT_APP_MIN_TITLE}`);
    const maxTitle: number = parseInt(`${process.env.REACT_APP_MAX_TITLE}`);
    const minMetaDesc: number = parseInt(`${process.env.REACT_APP_MIN_META_DESC}`);
    const maxMetaDesc: number = parseInt(`${process.env.REACT_APP_MAX_META_DESC}`);
    const minNbrOfWords: number = 100;
    const maxNbrOfWords: number = 2000;
    const minDeadline: string = moment().add(48, 'hours').format('YYYY-MM-DD');

    // Initial Formik form values
    const initialValues: any = {
        keywordPost: '',
        title: '',
        metaDesc: '',
        keywordPixabay: '',
        brief: '',
        type: '',
        optimization: 80,
        nbrWords: 500,
        deadlineAt: minDeadline,
        hasSite: false,
        instantPublish: true,
        plannedForDate: minDeadline,
        plannedForHour: 0 // Midnight
    };

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        keywordPost : Yup
            .string()
            .required("Veuillez spécifier un mot-clé pour l'article"),
        title: Yup
            .string(),
        metaDesc: Yup
            .string(),
        type: Yup
            .string()
            .required("Veuillez spécifier un type d'article"),
        nbrWords: Yup
            .number()
            .typeError('Veuillez saisir un nombre valide')
            .required("Veuillez spécifier un nombre de mots pour l'article")
            .min(minNbrOfWords, `Le nombre minimum de mots est fixé à ${minNbrOfWords}`)
            .max(maxNbrOfWords, `Le nombre maximum de mots est fixé à ${maxNbrOfWords}`),
        deadlineAt: Yup
            .date()
            .required("Veuillez spécifier une deadline pour l'article")
            .min(minDeadline, 'La deadline ne peut être antérieure à la date du jour +48h'),
        plannedForDate: Yup
            .date()
            .test(
                'required',
                "Veuillez spécifier une date de publication de l'article",
                function (plannedForDate: Date|undefined) {
                    return false === this.parent.instantPublish ? undefined !== plannedForDate : true;
                }
            )
            .test(
                'afterDeadline',
                'La date de publication ne peut pas être prévue avant la deadline',
                function (plannedForDate: Date|undefined) {
                    return true === this.parent.instantPublish ?
                        true : (plannedForDate ? moment(plannedForDate).isSameOrAfter(moment(this.parent.deadlineAt)) : true);
                }
            )
    });

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve all existing Post type
        null === postTypes && PostTypeAPIs.getPostTypes()
        // On successful call, store post types in hook
        .then((data: Array<IPostType>) => data && setPostTypes(data));
    }, [postTypes])

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve all existing Thematics
        null === thematics && ThematicAPIs.getThematics()
        // On successful call, store thematics in hook
        .then((data: Array<IPostType>) => data && setThematics(data));
    }, [thematics])

    // Callback used to submit Formik form
    const handleSubmit = useCallback((values: FormikValues) => {
        if (true === values.hasSite && (null === selectedSite || null === selectedCategory)) {
            // Display error about site information missing
            setErrorSite(true);
        } else {
            // Hide error about site information missing
            setErrorSite(false);

            // Setup body for following API call
            const postBody = {
                site: true === values.hasSite ? selectedSite : null,
                category: true === values.hasSite ? selectedCategory : null,
                postType: values.type,
                postStatus: unassignedStatus!.id,
                keyword: values.keywordPost.trim(),
                params: {
                    title: values.title !== '' ? values.title.trim() : null,
                    metaDesc: values.metaDesc !== '' ? values.metaDesc.trim() : null,
                    keywordPixabay: values.keywordPixabay !== '' ? values.keywordPixabay.trim() : values.keywordPost.trim(),
                    brief: values.brief !== '' ? values.brief.trim() : null,
                    optimization: `${values.optimization}%`,
                    nbrWords: values.nbrWords
                },
                deadlineAt: values.deadlineAt,
                plannedFor: false === values.instantPublish ?
                    moment(values.plannedForDate).set({ hours: values.plannedForHour }).format('YYYY-MM-DDTHH:mm:ss')
                    : null
            };

            // POST the Post using our API
            PostAPIs.postPost(postBody)
            // Navigate to Posts recap on successful call
            .then(() => cookie.user && 'ROLE_REF' === cookie.user.mainRole ?
                history.push('/commands/owned') : history.push('/posts/gestion') 
            );
        }
    }, [selectedSite, selectedCategory, history, unassignedStatus, cookie.user])

    // Callback used to append options in select for publish time
    const createDayHoursOptions = useCallback(() => {
        const options: Array<any> = [];

        for (let i: number = 0; i < 24; ++i) {
            options.push(
                <option key={i} value={i}>{i < 10 ? `0${i}:00` : `${i}:00` }</option>
            );
        }

        return options;
    }, [])

    return (
        <>
            <p className='commandCreationTitle'>Création d'une demande d'article</p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => handleSubmit(values)}
            >
                {(formik: FormikValues) => (
                    <Form className='commandCreationForm' autoComplete='off'>
                        <div className='formColumn'>
                            <fieldset className='fieldset'>
                                <div className='_field'>
                                    <SplitText className='_label' text="Mot-clé de l'article" />  
                                    <Field className='_input' type='text' name='keywordPost' />
                                    <ErrorMessage className='_error' component='span' name='keywordPost' />
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text="Titre de l'article" />
                                    <Field
                                        className='_input'
                                        type='text'
                                        name='title'
                                        placeholder='Optionnel (Laisse le choix au rédacteur)'
                                    />
                                    {
                                        '' !== formik.values.keywordPost &&
                                        '' !== formik.values.title &&
                                        !formik.values.title.startsWith(`${formik.values.keywordPost} :`) && (
                                            <span className='_warning'>{`Votre titre devrait commencer par ${formik.values.keywordPost} :`}</span>
                                        )
                                    }
                                    {
                                        '' !== formik.values.title && minTitle > formik.values.title.length && (
                                            <span className='_warning'>{`Votre titre devrait posséder au minimum ${minTitle} caractéres`}</span>
                                        )
                                    }
                                    {
                                        '' !== formik.values.title && maxTitle < formik.values.title.length && (
                                            <span className='_warning'>{`Votre titre devrait posséder au maximum ${maxTitle} caractéres`}</span>
                                        )
                                    }
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text="Meta description de l'article" length={2} />
                                    <Field
                                        className='_textarea'
                                        as='textarea'
                                        name='metaDesc'
                                        placeholder='Optionnel (Laisse le choix au rédacteur)'
                                    />
                                    {
                                        '' !== formik.values.metaDesc && minMetaDesc > formik.values.metaDesc.length && (
                                            <span className='_warning'>{`Votre meta description devrait posséder au minimum ${minMetaDesc} caractéres`}</span>
                                        )
                                    }
                                    {
                                        '' !== formik.values.metaDesc && maxMetaDesc < formik.values.metaDesc.length && (
                                            <span className='_warning'>{`Votre meta description devrait posséder au maximum ${maxMetaDesc} caractéres`}</span>
                                        )
                                    }
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text="Mot-clé pour Pixabay" />  
                                    <Field
                                        className='_input'
                                        type='text'
                                        name='keywordPixabay'
                                        placeholder="Optionnel (Les images dépendrons du mot-clé de l'article)"
                                    />
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text="Consigne pour l'article" />
                                    <Field
                                        className='_textarea'
                                        as='textarea'
                                        name='brief'
                                        placeholder='Optionnel (Indiquer des consignes particulières)'
                                    />
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text="Type d'article" />
                                    <Field className='_semiSelect' as='select' name='type'>
                                        <option value='' disabled hidden>Sélectionnez un type ...</option>
                                        {
                                            null !== postTypes && (
                                                postTypes.map((postType: IPostType, index: number) => (
                                                    <option key={index} value={postType.id}>{postType.name}</option>
                                                ))
                                            )
                                        }
                                    </Field>
                                    <ErrorMessage className='_error' component='span' name='type' />
                                </div>
                            </fieldset>
                            {
                                cookie.user && cookie.user.roles.includes('ROLE_ADMIN') && (
                                    <fieldset className='fieldsetSpaceBetween'>
                                        <FormikCheckInput
                                            asField={false === formik.values.instantPublish}
                                            id='instantPublish'
                                            type='checkbox'
                                            name='instantPublish'
                                            label='Publication dès que possible'
                                            splitLabel
                                        />
                                        {
                                            false === formik.values.instantPublish && (
                                                <>
                                                    <div className='_semiField'>
                                                        <SplitText className='_label' text='Date de publication' />
                                                        <Field className='_dateInput' type='date' name='plannedForDate' />
                                                        <ErrorMessage className='_error' component='span' name='plannedForDate' />
                                                    </div>
                                                    <div className='_semiField'>
                                                        <SplitText className='_label' text='Heure de publication' />
                                                        <Field className='_semiSelect' as='select' name='plannedForHour'>
                                                            {createDayHoursOptions()}
                                                        </Field>
                                                        <ErrorMessage className='_error' component='span' name='plannedForHour' />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </fieldset>
                                )
                            }
                        </div>
                        <div className='formColumn'>
                            <fieldset className='fieldset'>
                                <div className='_field'>
                                    <SplitText className='_label' text="Taux d'optimisation" />
                                    <div className='optiStructure'>
                                        <Field className='_rangeInput' type='range' name='optimization' />
                                        <p className='value'>{formik.values.optimization}</p>
                                    </div>
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text='Nombre de mots' />
                                    <Field className='_semiInput' type='text' name='nbrWords' />
                                    <ErrorMessage className='_error' component='span' name='nbrWords' />
                                </div>
                                <div className='_field'>
                                    <SplitText className='_label' text='Deadline' />
                                    <Field className='_dateInput' type='date' name='deadlineAt' />
                                    <ErrorMessage className='_error' component='span' name='deadlineAt' />
                                </div>
                            </fieldset>
                            {
                                cookie.user && cookie.user.roles.includes('ROLE_ADMIN') && (
                                    <fieldset className='fieldset'>
                                        <FormikCheckInput
                                            asField={true === formik.values.hasSite}
                                            id='hasSite'
                                            type='checkbox'
                                            name='hasSite'
                                            label='Sélectionnez un site de destination'
                                            splitLabel
                                        />
                                        {
                                            true === formik.values.hasSite && (
                                                <>
                                                    <div className='_field'>
                                                        <SplitText className='_label' text="Thème de l'article" />
                                                        <select
                                                            className='_semiSelect'
                                                            value={selectedThematic ?? ''}
                                                            onChange={(event) => {
                                                                setSelectedThematic(event.currentTarget.value);
                                                                setSelectedSite(null);
                                                                setSelectedCategory(null);
                                                            }}>
                                                            <option value='' disabled hidden>Sélectionnez un thème ...</option>
                                                            {
                                                                null !== thematics && (
                                                                    thematics.map((thematic: IThematic, index: number) => (
                                                                        <option key={index} value={thematic.id}>{thematic.name}</option>
                                                                    ))
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    {
                                                        selectedThematic && (
                                                            <div className='_field'>
                                                                <SplitText className='_label' text="Site de destination de l'article" />
                                                                <select
                                                                    className='_semiSelect'
                                                                    value={selectedSite ?? ''}
                                                                    onChange={(event) => {
                                                                        setSelectedSite(event.currentTarget.value);
                                                                        setSelectedCategory(null);
                                                                    }}>
                                                                    <option value='' disabled hidden>Sélectionnez un site de destination ...</option>
                                                                    <SiteOptions thematicId={selectedThematic} />
                                                                </select>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        selectedSite && (
                                                            <div className='_field'>
                                                                <SplitText className='_label' text="Catégorie de l'article" />
                                                                <select
                                                                    className='_semiSelect'
                                                                    value={selectedCategory ?? ''}
                                                                    onChange={(event) => setSelectedCategory(event.currentTarget.value)}
                                                                >
                                                                    <option value='' disabled hidden>Sélectionnez une catégorie ...</option>
                                                                    <CategoryOptions siteId={selectedSite} />
                                                                </select>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        true === errorSite && (
                                                            <span className='_error'>Veuillez renseigner toutes les informations concernant le site</span>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </fieldset>
                                )
                            }
                        </div>
                        <div className='submitStructure'>
                            <button className='_button' type='submit'>
                                <p>Commander mon article</p>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CommandCreation;
