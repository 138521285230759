import React, { useState, useEffect } from 'react';
import UserAPIs from '../../APIs/UserAPIs';
import IUser from '../../interfaces/IUser';
import Editor from './Editor';
import Spinner from '../Spinner/Spinner';
import './EditorsGestion.scss';
import IEditorsVelocities from '../../interfaces/IEditorsVelocities';


const EditorsGestion: React.FC = () => {
    // Use of hooks
    const [editors, setEditors] = useState<Array<IUser>|null>(null);
    const [velocities, setVelocities] = useState<IEditorsVelocities|null>(null);

    // useEffect when component is mounting
    useEffect(() => {
        // Call our API to retrieve all editors
        null === editors && UserAPIs.getUsers({roles: 'ROLE_REDAC'})
        // On succesful call, store these editors in hook value
        .then((data: Array<IUser>) => data && setEditors(data));
    }, [editors])

    // useEffect when component is mounting
    useEffect(() => {
        // Call our API to retrieve all editors
        null === velocities && UserAPIs.getEditorsVelocities()
        // On succesful call, store these velocities in hook value
        .then((data: IEditorsVelocities) => data && setVelocities(data));
    }, [velocities])

    return (
        null !== editors && null !== velocities ? (
            <div className='editorsGestion'>
                {
                    editors.map((editor: IUser, index: number) => (
                        <Editor
                            key={index}
                            editor={editor}
                            velocity={velocities[editor.id]}
                        />
                    ))
                }
            </div>
        ) : (<Spinner />)
    );
};

export default EditorsGestion;
