import React, { useEffect, useState } from 'react';
import SiteAPIs from '../../APIs/SiteAPIs';
import ISite from '../../interfaces/ISite';

const SiteOptions: React.FC<{ thematicId: number|string }> = props => {
    // Use of hooks
    const [sites, setSites] = useState<Array<ISite>|null>(null);

    // useEffect when component is mounting
    useEffect(() => {
        // Call API to retrieve all existing Sites
        SiteAPIs.getSites({ thematic: props.thematicId })
        // On successful call, store Sites in hook
        .then((data: Array<ISite>) => data && setSites(data));
    }, [props.thematicId])

    return (
        <>
            {
                null !== sites && (
                    sites.map((site: ISite, index: number) => (
                        <option key={index} value={site.id}>{site.ndd}</option>
                    ))
                )
            }
        </>
    );
};

export default SiteOptions;
