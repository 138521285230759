import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/slices/activeModalSlice';
import Tools from '../../helpers/Tools';
import { ImPushpin, ImEye } from 'react-icons/im';
import DataDisplay from '../DataDisplay/DataDisplay';
import PostStatusTag from '../PostStatusTag/PostStatusTag';
import Tooltip from '../Tooltip/Tooltip';
import IPost from '../../interfaces/IPost';

const Command: React.FC<{ post: IPost }> = props => {
    // Use of redux
    const dispatch: any = useDispatch<any>();

    // Callback used to open modal and give it params
    const doOpenModal = useCallback((name: string, params: any) => {
        // Use redux to open targetted modal
        dispatch(openModal({ name: name, params: params }));
    }, [dispatch])

    return (
        <div className='commandComponent'>
            <ImPushpin className='pinIcon' />
            <div className='dataStructure'>
                <DataDisplay
                    inline
                    label='Commande du'
                    data={Tools.humanizeTimestamp(props.post.createdAt)}
                />
                <DataDisplay
                    inline
                    label='Mot-clé'
                    data={props.post.keyword}
                />
                <DataDisplay
                    inline
                    label="Taux d'opti."
                    data={'0%' === props.post.params.optimization ? 'Libre' : props.post.params.optimization}
                />
                <DataDisplay
                    inline
                    label="Type d'article"
                    data={props.post.postType.name}
                />
            </div>
            <PostStatusTag
                className='statusTag'
                postStatus={props.post.postStatus}
            />
            {
                ['unverified', 'verified'].includes(props.post.postStatus.slug) && (
                    <>
                        <ImEye
                            className='_actionButton action'
                            data-tip="Consultion de l'article"
                            data-for='consultPost'
                            onClick={() => doOpenModal('viewPost', { post: props.post })}
                        />
                        <Tooltip id='consultPost'/>
                    </>
                )
            }
        </div>
    )
};

export default Command;
