import React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import IPostStatus from '../../interfaces/IPostStatus';

// Styled status tag
const StyledPostStatus: any = styled.p<{ color: string }>`
    text-align: center;
    color: white;
    padding: 0.2vh 0.7vh;
    background-color: ${props => props.color};
    border-radius: 5px;
`;

const PostStatusTag: React.FC<{
    postStatus: IPostStatus,
    className?: string|undefined,
    onClick?: (postStatusId: number) => void
}> = props => {
    // Callback used when user click on tag
    const handleTagClick = useCallback((postStatusId: number) => {
        // Trigger props callback if given
        props.onClick && props.onClick(postStatusId);
    }, [props]);

    return (
        <StyledPostStatus
            className={props.className}
            color={props.postStatus.color}
            onClick={() => handleTagClick(props.postStatus.id)}
        >
            {`${props.postStatus.name} ${props.onClick ? 'x' : ''}`}
        </StyledPostStatus>
    );
};

export default PostStatusTag;
