import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import IUser from '../../interfaces/IUser';
import './EditorCard.scss';

const EditorCard: React.FC<{
    editor: IUser,
    onCardClick: (editorId: number) => void,
    className?: string|undefined,
    selected?: boolean|undefined
}> = props => {
    return (
        <div
            className={`editorCardComponent ${props.selected ? 'editorCardSelected' : ''} ${props.className ?? ''}`}
            onClick={() => props.onCardClick(props.editor.id)}
        >
            <FaUserCircle className='avatar' />
            <p className='editor'>{`${props.editor.name} ${props.editor.lastname}`}</p>
        </div>
    );
};

export default EditorCard;
