import React, { useCallback, useEffect, useState } from 'react';
import BridgeAPIs from '../../APIs/BridgeAPIs';
import Tools from '../../helpers/Tools';
import Spinner from '../Spinner/Spinner';
import ImageSelector from '../ImageSelector/ImageSelector';
import ImagePreview from '../ImagePreview/ImagePreview';
import IPixabayHits from '../../interfaces/IPixabayHits';
import './PixabaySelector.scss';

const PixabaySelector: React.FC<{
    keyword: string|null,
    onChange: (image: string|null) => void,
    initialImage?: string|null|undefined,
    className?: string|undefined
}> = props => {
    // Use of hooks
    const [imagesHits, setImagesHits] = useState<Array<string>|null>(null);
    const [image, setImage] = useState<string|null|undefined>(undefined);

    // useEffect when component is mounting
    useEffect(() => {
        // Try to retrieve the given image to store it in hook
        undefined === image && setImage(props.initialImage ?? null);
    }, [props.initialImage, image])

    // useEffect happening when previous one has finished
    useEffect(() => {
        // If call was not made yet & we have a keyword & we have no given image
        if (null === imagesHits && null !== props.keyword && null === image) {
            // Call our API to retrieve Pixabay hits
            BridgeAPIs.getPixabayHits({ keyword: props.keyword })
            .then((data: IPixabayHits) => {
                // Store these hits in hook
                data && setImagesHits(Tools.convertHitsToUrls(data.hits));
            });
        }
    }, [imagesHits, props.keyword, image])

    // Callback used when preview is closed
    const handleClosePreview = useCallback(() => {
        // Reset image hook value
        setImage(null);
        // Notify that image selection changed
        props.onChange(null);
    }, [props])

    return (
        typeof image === 'string' ? (
            <ImagePreview
                image={image}
                onClose={() => handleClosePreview()}
            />
        ) : null !== imagesHits ? (
            imagesHits.length > 0 ? (
                <ImageSelector
                    className={props.className}
                    images={imagesHits}
                    onChange={props.onChange}
                />
            ) : (
                <p className='pixabayNothingFound'>Aucune image trouvée ...</p>
            )
        ) : <Spinner extend size={5} padding={'1vh'}/>
    );
};

export default PixabaySelector;
