import GenericAPIs from './GenericAPIs';
import Cookie from '../helpers/Cookie';

/**
 * BridgeAPIs class.
 */
export default class BridgeAPIs {
    /**
     * Get Pixabay hits from keyword
     * 
     * @param apiKey
     * @param query
     */
    public static async getPixabayHits(query?: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiGetCall(`api_bridge/pixabay_hits`, apiKey, query, false);
    }

    /**
     * Publish Post on WordPress & index Post url
     * 
     * @param body
     */
    public static async publishPost(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(`api_bridge/wp_publish`, apiKey, body, undefined, false);
    }

    /**
     * Retrieve optimization data
     *
     * @param body
     */
    public static async getOptimizationData(body: any) {
        // Retrieve apiKey
        const apiKey = Cookie.getUserApiKey();

        // do not notify on success
        return GenericAPIs.apiPostCall(`api_bridge/unpointfr_optimization`, apiKey, body, undefined, false);
    }
};
