import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/slices/activeModalSlice';
import { resetTextEditorState } from '../../store/slices/textEditorSlice';
import { useHistory, useLocation } from 'react-router-dom';
import Tools from '../../helpers/Tools';
import LoginAPIs from '../../APIs/LoginAPIs';
import ModalInstanciateMode from '../Modal/ModalInstanciateMode';
import NavLink from './NavLink';
import Tooltip from '../Tooltip/Tooltip';
import { FaUserCircle, FaReact, FaHome } from 'react-icons/fa';
import { GiReturnArrow } from 'react-icons/gi';
import './Header.scss';

const Header: React.FC = () => {
    // Use react-cookie
    const [cookie, /* setCookie not used */, removeCookie] = useCookies(['user']);

    // Use of redux
    const dispatch: any = useDispatch<any>();

    // Use of react-router-dom
    const history = useHistory();
    const { pathname } = useLocation();

    // Callback use to redirect to a specific location
    const leaveLocation = useCallback((redirection: string) => {
        // Reset textEditor redux state
        dispatch(resetTextEditorState());

        switch (redirection) {
            case 'back':
                // Move to previous location
                history.goBack();
                break;

            case 'logout':
                // Excute before logout
                LoginAPIs.postConnection({ id: cookie.user.id, logout: true })
                // On successful call
                .then(() => {
                    // Remove user cookie
                    removeCookie('user');
                    // And redirect to root
                    history.push('/');
                });
                break;
            
            default:
                history.push(redirection);
                break;
        }
    }, [history, dispatch, cookie, removeCookie])

    // Callback used when user wants to leave location
    const safelyLeaveLocation = useCallback((redirection: string) => {
        if (pathname !== redirection) {
            // Check if we are in instanciate mode
            Tools.locationInInstanciateMode(pathname) ?
                // Open modal to validate leaving
                dispatch(openModal({ name: 'instanciateMode', params: redirection }))
                // Redirection depends on param
                : leaveLocation(redirection);
        } 
    }, [pathname, dispatch, leaveLocation])

    // Callback which handle home redirection
    const handleHomeClick = useCallback(() => {
        // Verify if we need to open modal for instanciate mode
        safelyLeaveLocation(`${process.env.REACT_APP_STARTING_PAGE}`);
    }, [safelyLeaveLocation])

    return (
        <>
            <ModalInstanciateMode onModalValidation={leaveLocation}/>
            <section className='headerComponent'>
                <header className='headband'>
                    <div className='logoContainer'>
                        <FaReact className='logo' onClick={() => handleHomeClick()} />
                        <p className='appName'>{`${process.env.REACT_APP_NAME}`}</p>
                    </div>
                    <div className='userContainer'>
                        <FaUserCircle className='avatar' />
                        <div className='userStructure'>
                            {
                                cookie.user && (
                                    <p className='user'>{cookie.user.name} {cookie.user.lastname}</p>
                                )
                            }
                            <p className='logoutStructure' onClick={() => safelyLeaveLocation('logout')}>
                                Se déconnecter
                            </p>
                        </div>
                    </div>
                </header>
                <nav className='navbarStructure'>
                    <div className='navbar'>
                        <FaHome className='home' onClick={() => handleHomeClick()} />
                        <NavLink
                            className='link'
                            label='Mon profil'
                            to='/profile'
                            onClick={safelyLeaveLocation}
                        />
                        {
                            cookie.user && 'ROLE_REDAC' === cookie.user.mainRole && (
                                <NavLink
                                    className='link'
                                    label='Articles à rédiger'
                                    to='/posts/todo'
                                    onClick={safelyLeaveLocation}
                                />
                            )
                        }
                        {
                            cookie.user && cookie.user.roles.includes('ROLE_MODO') && (
                                <>
                                    <NavLink
                                        className='link'
                                        label='Gestion des articles'
                                        to='/posts/gestion'
                                        onClick={safelyLeaveLocation}
                                    />
                                    <NavLink
                                        className='link'
                                        label='Gestion des rédacteurs'
                                        to='/editors/gestion'
                                        onClick={safelyLeaveLocation}
                                    />
                                </>
                            )
                        }
                        {
                            cookie.user && cookie.user.roles.includes('ROLE_ADMIN') && (
                                <NavLink
                                    className='link'
                                    label='Gestion des sites'
                                    to='/sites/create'
                                    onClick={safelyLeaveLocation}
                                />
                            )
                        }
                        {
                            cookie.user && 'ROLE_REF' === cookie.user.mainRole && (
                                    <NavLink
                                        className='link'
                                        label='Mes commandes'
                                        to='/commands/owned'
                                        onClick={safelyLeaveLocation}
                                    />
                            )
                        }
                        {
                            cookie.user && cookie.user.roles.includes('ROLE_REF') && (
                                    <NavLink
                                        className='link'
                                        label='Passer une commande'
                                        to='/commands/creation'
                                        onClick={safelyLeaveLocation}
                                    />
                            )
                        }
                        <NavLink
                            className='link'
                            label='Outil de rédaction'
                            to='/redac_tool'
                            onClick={safelyLeaveLocation}
                        />
                        {
                            cookie.user && cookie.user.roles.includes('ROLE_DEV') && (
                                <NavLink
                                    className='link'
                                    label='Espace DEV'
                                    to='/dev'
                                    onClick={safelyLeaveLocation}
                                />
                            )
                        }
                    </div>
                    {
                        Tools.locationWithGoBackNav(pathname) && (
                            <>
                                <GiReturnArrow
                                    className='previous'
                                    data-tip='Précedent'
                                    data-for='goBack'
                                    onClick={() => safelyLeaveLocation('back')}
                                />
                                <Tooltip id='goBack'/>
                            </>
                        )
                    }
                </nav>
            </section>
        </>
    );
};

export default Header;
