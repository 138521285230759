import moment, { Moment } from 'moment-timezone';

const start: Array<string> = `${process.env.REACT_APP_START_CONNECTION}`.split(':');
const end: Array<string> = `${process.env.REACT_APP_END_CONNECTION}`.split(':');

const connectionInterval: { begin: Moment, end: Moment } = {
    begin: moment().set({ hour: parseInt(start[0]), minute: parseInt(start[1]), second: 0, millisecond: 0 }),
    end: moment().set({ hour: parseInt(end[0]), minute: parseInt(end[1]), second: 0, millisecond: 0 })
};

export default connectionInterval;
