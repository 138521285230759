import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';

const ErrorToast: React.FC<{ open: boolean, message: string, closable?: boolean|undefined, onCloseCallback?: () => void, className?: string|undefined}> = props => {
    // Use of react hooks
    const [toastOpened, setToastOpened] = useState<boolean|null>(null);

    // Callback to handle toast close
    const handleClose = useCallback(() => {
        // Change toastOpened hook value to close the toast
        setToastOpened(false);
        // If given callback, call it
        props.onCloseCallback && props.onCloseCallback()
    }, [props])

    // useEffect each time props.open value changes
    useEffect(() => {
        setToastOpened(props.open);
    }, [props.open])

    return (
        true === toastOpened ? (
            <div className={`_errorToast ${props.className ?? ''}`}>
                <AiOutlineStop className='_toastIcon' />
                <div className='_toastContent'>
                    <p>{props.message}</p>
                    {
                        true === props.closable && (
                            <ImCross className='_toastClose' onClick={() => handleClose()} />
                        )
                    }
                </div>
            </div>
        ) : null
    );
};

export default ErrorToast;