import { AxiosResponse } from 'axios';
import Login from '../helpers/Login';
import ILoginInterface from '../interfaces/ILoginInterface';

/**
 * LoginAPIs class.
 */
export default class LoginAPIs {
    /**
     * Get user information.
     * 
     * @param query
     */
    public static async getLogin(query: ILoginInterface) {
        // Call Login baseUrl with GET method
        return Login.get('login', { params: query })
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

                // If we have a response, log in console
                console.log(`${msg} with query :`, query, 'returns: ', response);

                // Return this response
                return response.data;
            })
            .catch((error: any) => {
                // Message for notifier and console
                const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                console.log(`${msg} with query :`, query);

                return null;
            });
    }

    /**
     * Post user connexion.
     * 
     * @param body
     */
    public static async postConnection(body: any) {
        // Call Login baseUrl with GET method
        return Login.post('connection', body)
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

                // If we have a response, log in console
                console.log(`${msg} with body :`, body, 'returns: ', response);

                // Return this response
                return response.data;
            })
            .catch((error: any) => {
                // Message for notifier and console
                const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                console.log(`${msg} with query :`, body);

                return null;
            });
    }
};
