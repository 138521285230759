import { store } from 'react-notifications-component';
import IApiError from '../interfaces/IApiError';
import 'react-notifications-component/dist/theme.css';

/**
 * Notifier class.
 */
export default class Notifier {
    /**
     * Create a notification on App.
     * 
     * @param title 
     * @param message 
     * @param type 
     * @param duration 
     */
    public static createNotification(title: string, message: string, type: string = 'danger', duration: number = 5000) {
        store.addNotification({
            title: title,
            message: message.toString(),
            type: type,
            container: 'top-right',
            animationIn: [ 'animated', 'fadeIn' ],
            animationOut: [ 'animated', 'fadeOut' ],
            dismiss: {
                duration: duration,
                onScreen: true
            }
        });
    }

    /**
     * Create notifications from an array of API errors.
     * 
     * @param errors
     * @param duration
     */
    public static notifyApiErrors(errors: IApiError[], duration: number = 10000) {
        errors.map((error: IApiError) => {
            console.log(error)
            // Custom title if error origin
            const notifTitle: string = undefined !== error.origin ?
                `Erreur API sur le champ '${error.origin}'` : 'Erreur API survenue';

            this.createNotification(notifTitle, error.message, 'danger', duration);

            return null;
        })
    }
};
