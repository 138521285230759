import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, IActiveModalState, selectActiveModal } from '../../store/slices/activeModalSlice';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import SplitText from '../SplitText/SplitText';
import './Modal.scss';

const ModalDatePicker: React.FC<{
    title: string,
    onModalValidation: (params: any, date: string) => void,
    splitTitleLength?: number|undefined
}> = props => {
    // Use of hooks
    const [isOpen, setIsOpen] = useState<boolean|null>(null);
    const [date, setDate] = useState<string|null>(null);
    const modalNameRef = useRef<string>('datePicker');

    // Use of redux
    const dispatch: any = useDispatch<any>();
    const activeModal: IActiveModalState|null = useSelector(selectActiveModal);

    // useEffect when component is mounting
    // Whenever activeModal redux state change
    useEffect(() => {
        if (null !== activeModal) {
            // Verify if it concerns this modal
            setIsOpen(modalNameRef.current === activeModal.name);
        }
    }, [activeModal])

    // Callback used when we need to close the modal
    const handleCloseModal = useCallback(() => {
        // Reset date hook value
        setDate(null);
        // Close modal using redux state
        dispatch(closeModal());
    }, [dispatch])

    // Callback used when we need to validate the modal
    const handleValidateModal = useCallback(() => {
        if (null !== activeModal) {
            // Trigger onModalValidation callback given in props
            null !== date && props.onModalValidation(activeModal.params, date);
            // Callback to properly close modal
            handleCloseModal();
        }
    }, [activeModal, props, date, handleCloseModal])

    return (
        <Modal
            isOpen={isOpen ?? false}
            overlayClassName='overlay'
            className='modalDatePicker'
        >
            <div className='header'>
                <SplitText
                    className='title'
                    text={props.title}
                    secondHalfColor='white'
                    length={props.splitTitleLength}
                />
                <ImCross className='close' onClick={handleCloseModal}/>
            </div>
            <div className='body'>
                <input type='date' className='_dateInput' value={date ?? ''} onChange={(event) => setDate(event.currentTarget.value)} />
            </div>
            <div className='footer'>
                <button className='cancel' onClick={handleCloseModal}>Annuler</button>
                <button className='validate' onClick={handleValidateModal}>Valider</button>
            </div>
        </Modal>
    );
};

export default ModalDatePicker;
