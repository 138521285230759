import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectPostStatusBySlug } from '../../store/slices/postStatusesSlice';
import PostAPIs from '../../APIs/PostAPIs';
import UserObjectiveAPIs from '../../APIs/UserObjectiveAPIs';
import Spinner from '../Spinner/Spinner';
import ObjectiveTable from '../ObjectiveTable/ObjectiveTable';
import Post from './Post';
import IPost from '../../interfaces/IPost';
import IFormattedUserObjective from '../../interfaces/IFormattedUserObjective';
import IPostStatus from '../../interfaces/IPostStatus';
import './TodoPosts.scss';

const TodoPosts: React.FC = () => {
    // Use of hooks
    const [todoPosts, setTodoPosts] = useState<Array<IPost>|null>(null);
    const [userObjectives, setUserObjectives] = useState<Array<IFormattedUserObjective>|null>(null);

    // Use react-cookie
    const [cookie] = useCookies(['user']);

    // Use of redux
    const todoStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('todo'));
    const savedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('saved'));
    const refusedStatus: IPostStatus|null = useSelector(selectPostStatusBySlug('refused'));

    // useEffect when component is mounting
    useEffect(() => {
        // Retrieve user objectives from API
        null === userObjectives && UserObjectiveAPIs.getUserObjectives({
            user: cookie.user.id,
            periodicities: '1, 30',
            formatted: true
        })
        // on successful call, store UserObjectives in hook value
        .then((data: Array<IFormattedUserObjective>) => data && setUserObjectives(data));
    }, [cookie.user, userObjectives])

    // useEffect when component is mounting & after previous one
    useEffect(() => {
        if (todoStatus && savedStatus && refusedStatus && null !== userObjectives && null === todoPosts) {
            // Retrieve posts todo & saved from API
            PostAPIs.getPosts({
                editor: cookie.user.id,
                postStatus: `${todoStatus!.id},${savedStatus!.id},${refusedStatus!.id}`,
                all: true,
            })
            // On successful call, store TodoPosts in hook value
            .then((data: Array<IPost>) => data && setTodoPosts(data));
        }
    }, [cookie.user, userObjectives, todoPosts, todoStatus, savedStatus, refusedStatus])

    return (
        null!== todoPosts && null !== userObjectives ? (
            <>
                {
                    userObjectives[cookie.user.id] && (
                        <div className='objectiveTablesContainer'>
                            <ObjectiveTable
                                className='objectiveTable'
                                title='Avancement des articles'
                                data={userObjectives[cookie.user.id].articles}
                            />
                            <ObjectiveTable
                                className='objectiveTable'
                                title='Avancement du nombre de mots'
                                data={userObjectives[cookie.user.id].words}
                            />
                        </div>
                    )
                }
                {
                    0 < todoPosts.length ? (
                        <>
                        <div className='todoPostsHeaders'>
                            <p className='header widthStatus'>Statut</p>
                            <p className='header widthKeyword'>Mot-clé</p>
                            <p className='header widthType'>Type</p>
                            <p className='header widthWords'>Nbr. de mots</p>
                            <p className='header widthOpti'>Taux d'optimisation</p>
                            <p className='header widthActions'>Actions</p>
                        </div>
                        <div className='todoPostsList'>
                            {
                                todoPosts.map((todoPost: IPost, index: number) => (
                                    <Post key={index} post={todoPost}/>
                                ))
                            }
                        </div>
                        </>
                    ) : (<p>Aucun article à rédiger pour le moment ...</p>)
                }
            </>
        ) : (<Spinner extend />)
    );
};

export default TodoPosts;
