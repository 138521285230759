import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { FiPauseCircle, FiPlayCircle } from 'react-icons/fi';
import './BackgroundVideo.scss';

// Video styled component
const Video: any = styled.video<{ opacity: string }>`
    opacity: ${props => props.opacity};
    min-width: 100vw;
    min-height: 100vh;
`;

const BackgroundVideo: React.FC<{ video: string, opacity?: number|undefined, type?: string|undefined }> = props => {
    // Use of hooks
    const [videoPlay, setVideoPlay] = useState<boolean>(true);
    const videoRef = useRef<HTMLVideoElement>(null);

    // Callback to handle video state
    const handlePauseResume = useCallback(() => {
        if (videoRef.current) {
            // Change the video state
            true === videoPlay ? videoRef.current.pause() : videoRef.current.play();
        }
        // Update hook value for future handles
        setVideoPlay(!videoPlay);
    }, [videoPlay])

    return (
        <div className='backgroundVideo'>
            <Video opacity={props.opacity ?? '0.7'} ref={videoRef} autoPlay muted loop>
                <source src={props.video} type={props.type ?? 'video/mp4'}/>
                Votre navigateur ne supporte pas ce type de vidéo.
            </Video>
            {
                true === videoPlay ? (
                    <FiPauseCircle className='videoControls' onClick={() => handlePauseResume()} />
                ) : (
                    <FiPlayCircle className='videoControls' onClick={() => handlePauseResume()} />
                )
            }
        </div>
    );
};

export default BackgroundVideo;
