import React from 'react';
import { useLocation } from 'react-router-dom';

const NavLink: React.FC<{ label: string, to: string, onClick: (to: string) => void, className?: string|undefined} > = props => {
    // Use of react-router-dom
    const { pathname } = useLocation();

    return (
        <button
            className={`${props.className ?? ''} ${pathname.startsWith(props.to) ? 'active': ''}`}
            onClick={() => props.onClick(props.to)}
        >
            {props.label}
        </button>
    );
};

export default NavLink;
