import React from 'react';
import styled from 'styled-components';
import Tools from '../../helpers/Tools';
import './ProgressBar.scss';

// Styled component Bar
const Bar: any = styled.span<{ percent: string }>`
    height: 100%;
    width: ${props => `${props.percent}%`};
    background-color: ${props => props.color};
`;

const ProgressBar: React.FC<{ current: number, aim: number, color: string, className?: string|undefined }> = props => {
    // Determine color & percent
    const color: string = props.color ?? Tools.getObjectiveColor(props.current, props.aim);
    const percent: number = Tools.getPercent(props.current, props.aim);

    return (
        <div className={`progressBarComponent ${props.className ?? ''}`}>
            <Bar percent={percent} color={color} />
        </div>
    );
};

export default ProgressBar
