import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/slices/activeModalSlice';
import moment from 'moment-timezone';
import Tools from '../../helpers/Tools';
import PostStatusTag from '../PostStatusTag/PostStatusTag';
import DataDisplay from '../DataDisplay/DataDisplay';
import Tooltip from '../Tooltip/Tooltip';
import {
    GiExtraTime,
    FaKey,
    FaFlagCheckered,
    FaFeatherAlt,
    FaFolder,
    FaUserCircle,
    ImBin,
    ImEye,
    ImEyePlus,
    MdDateRange,
    MdAssignmentInd,
    RiArchiveDrawerFill,
    AiOutlineAim,
    AiOutlineFieldNumber,
    BsGearFill,
    HiLink
} from 'react-icons/all';
import IPost from '../../interfaces/IPost';

const Post: React.FC<{ post: IPost }> = props => {
    // Use of react-cookies
    const [cookies] = useCookies(['user']);

    // Use of react-router-dom
    const history = useHistory();

    // Use of redux
    const dispatch: any = useDispatch<any>();

    // Callback used to open modal and give it params
    const doOpenModal = useCallback((name: string, params: any) => {
        // Use redux to open targetted modal
        dispatch(openModal({ name: name, params: params }));
    }, [dispatch])

    return (
        <div className={`postContainer ${moment() > moment(props.post.deadlineAt) ? 'postUrgent' : ''}`}>
            <div className='headStructure'>
                <GiExtraTime className='icon' />
                <p className='deadline'>{Tools.humanizeTimestamp(props.post.deadlineAt, true)}</p>
                <PostStatusTag className='statusTag' postStatus={props.post.postStatus}/>
            </div>
            <div className='contentStructure'>
                <div className='topStructure'>
                    <DataDisplay
                        inline
                        icon={FaKey}
                        label='Mot-clé'
                        data={props.post.keyword}
                        fallback={props.post.params.keywordPost}
                    />
                    <DataDisplay
                        inline
                        icon={AiOutlineAim}
                        label='Type'
                        data={props.post.postType.name}
                    />
                    {
                        cookies.user.roles.includes('ROLE_ADMIN') && (
                            <>
                                <DataDisplay
                                    inline
                                    icon={FaFlagCheckered}
                                    label='Site de destination'
                                    data={props.post.params.destination}
                                    fallback={props.post.site ? props.post.site.ndd : undefined}
                                />
                                <DataDisplay
                                    inline
                                    icon={FaFolder}
                                    label='Slug de categorie'
                                    data={props.post.params.category}
                                    fallback={props.post.category ? props.post.category.name : undefined}
                                />
                            </>
                        )
                    }
                    <DataDisplay
                        inline
                        icon={AiOutlineFieldNumber}
                        label='Nombre de mots'
                        data={props.post.params.nbrWords}
                    />
                    <DataDisplay
                        inline
                        icon={BsGearFill}
                        label="Taux d'opti."
                        data={'0%' === props.post.params.optimization ? 'Libre' : props.post.params.optimization}
                    />
                    <DataDisplay
                        inline
                        className='date'
                        icon={MdDateRange}
                        label='Crée le'
                        data={Tools.humanizeTimestamp(props.post.createdAt)}
                    />
                    {
                        props.post.assignedAt && null !== props.post.editor &&(
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Attribué le'
                                data={Tools.humanizeTimestamp(props.post.assignedAt)}
                            />
                        )
                    }
                    {
                        props.post.updatedAt && 'saved' === props.post.postStatus.slug && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Dernière modification le'
                                data={Tools.humanizeTimestamp(props.post.updatedAt)}
                            />
                        )
                    }
                    {
                        props.post.writtenAt && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Rédigé le'
                                data={Tools.humanizeTimestamp(props.post.writtenAt)}
                            />
                        )
                    }
                    {
                        props.post.verifiedAt && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Validé le'
                                data={Tools.humanizeTimestamp(props.post.verifiedAt)}
                            />
                        )
                    }
                    {
                        props.post.plannedFor && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Publication prévue le'
                                data={Tools.humanizeTimestamp(props.post.plannedFor)}
                            />
                        )
                    }
                    {
                        props.post.publishedAt && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Publié le'
                                data={Tools.humanizeTimestamp(props.post.publishedAt)}
                            />
                        )
                    }
                    {
                        props.post.archivedAt && (
                            <DataDisplay
                                inline
                                className='date'
                                icon={MdDateRange}
                                label='Archivé le'
                                data={Tools.humanizeTimestamp(props.post.archivedAt)}
                            />
                        )
                    }
                </div>
                <div className='bottomStructure'>
                    <div className='editorStructure'>
                        {
                            props.post.assignedAt && null !== props.post.editor ? (
                                <>
                                    <FaUserCircle className='avatar' />
                                    <p className='user'>
                                        {props.post.editor.name} {props.post.editor.lastname}
                                    </p>
                                </>
                            ) : (
                                <p>Cet article n'est pas encore assigné à un rédacteur ...</p>
                            )
                        }
                    </div>
                    <div className='actionsStructure'>
                        {
                            !['unassigned', 'todo'].includes(props.post.postStatus.slug) && (
                                <>
                                    <ImEye
                                        className='_actionButton action'
                                        data-tip="Consulter l'article"
                                        data-for='consultPost'
                                        onClick={() => doOpenModal('viewPost', { post: props.post })}
                                    />
                                    <Tooltip id='consultPost'/>
                                </>
                            )
                        }
                        {
                            null !== props.post.publishedAt && (
                                <>
                                    <ImEyePlus
                                        className='_actionButton action'
                                        data-tip="Voir l'article en ligne"
                                        data-for='viewPostOnline'
                                        onClick={() => window.open(props.post.content.url, '_blank')}
                                    />
                                    <Tooltip id='viewPostOnline'/>
                                    <HiLink
                                        className='_actionButton action'
                                        data-tip="Copier l'url de l'article"
                                        data-for='copyLink'
                                        onClick={() => navigator.clipboard.writeText(props.post.content.url)}
                                    />
                                    <Tooltip id='copyLink'/>
                            </>
                            )
                        }
                        {
                            cookies.user.roles.includes('ROLE_ADMIN') &&
                            'unverified' === props.post.postStatus.slug && (
                                <>
                                    <FaFeatherAlt
                                        className='_actionButton action'
                                        data-tip="Éditer l'article"
                                        data-for='editPost'
                                        onClick={() => history.push(`/posts/todo/edit/admin/${props.post.id}`)}
                                    />
                                    <Tooltip id='editPost'/>
                                </>
                            )
                        }
                        {
                            ['todo', 'unassigned', 'saved', 'refused', 'unverified'].includes(props.post.postStatus.slug) && (
                                <>
                                    <MdAssignmentInd
                                        className='_actionButton action'
                                        data-tip="Assigner l'article"
                                        data-for='assignPost'
                                        onClick={() => doOpenModal('editorPicker', props.post)}
                                    />
                                    <Tooltip id='assignPost'/>
                                    <MdDateRange
                                        className='_actionButton action'
                                        data-tip="Changer la deadline"
                                        data-for='changeDeadline'
                                        onClick={() => doOpenModal('datePicker', props.post)}
                                    />
                                    <Tooltip id='changeDeadline'/>
                                </>
                            )
                        }
                        {
                            cookies.user.roles.includes('ROLE_ADMIN') &&
                            'archived' !== props.post.postStatus.slug && (
                                <>
                                    <RiArchiveDrawerFill
                                        className='_actionButton action'
                                        data-tip="Archiver l'article"
                                        data-for='archivePost'
                                        onClick={() => doOpenModal('archiveConfirmation', props.post.id)}
                                    />
                                    <Tooltip id='archivePost'/>
                                </>
                            )
                        }
                        {
                            cookies.user.roles.includes('ROLE_ADMIN') &&
                            // Post can be published but with slug 'archived'
                            // So we need the following condition
                            null === props.post.publishedAt && (
                                <>
                                    <ImBin
                                        className='_actionButton action'
                                        data-tip="Supprimer l'article"
                                        data-for='deletePost'
                                        onClick={() => doOpenModal('deleteConfirmation', props.post.id)}
                                    />
                                    <Tooltip id='deletePost'/>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Post;
