import React from 'react';
import Modal from 'react-modal';
import SplitText from '../SplitText/SplitText';
import Spinner from '../Spinner/Spinner';
import './Modal.scss';

const ModalWaiting: React.FC<{ isOpen: boolean, title: string }> = props => {
    return (
        <Modal
            isOpen={props.isOpen}
            overlayClassName='overlay'
            className='modalWaiting'
        >
            <div className='header'>
                <SplitText
                    className='title'
                    text={props.title}
                    secondHalfColor='white'
                />
            </div>
            <div className='body'>
                <Spinner extend />
            </div>
        </Modal>
    );
};

export default ModalWaiting;
