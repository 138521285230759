import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SplitText from '../SplitText/SplitText';
import * as Yup from 'yup';
import OptimizationData from '../OptimizationData/OptimizationData';
import TipTap from '../TipTap/TipTap';
import './RedacTool.scss';

const RedacTool: React.FC = () => {
    // Use of hooks
    const [keyword, setKeyword] = useState<string|null>(null);

    // Initial Formik form values
    const initialValues: any = {
        keyword: ''
    };

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        keyword : Yup
            .string()
            .required('Veuillez spécifier un mot-clé avant de poursuivre')
    });

    return (
        <>
            <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { setKeyword(values.keyword) }}
            >
                <Form>
                    <div className='_semiField'>
                        <SplitText className='_label' text="Mot-clé de l'article" />  
                        <Field
                            className='_input'
                            type='text'
                            name='keyword'
                            placeholder='Écrivez votre mot-clé puis valider'
                        />
                        <ErrorMessage className='_error' component='span' name='keyword' />
                    </div>
                    <button className='_button' type='submit'>
                        <p>Valider</p>
                    </button>
                </Form>
            </Formik>
            {
                keyword && (
                    <div className='optimizedEditor'>
                        <OptimizationData aimScore={80} query={keyword}/>
                        <div className='editor'>
                            <SplitText className='_label' text='Rédiger votre contenu' />
                            <TipTap />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default RedacTool;
